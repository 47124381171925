import { Box, Checkbox, IconButton, Typography, useTheme } from "@mui/material";
import {
  Column,
  getAllChildRowKeys,
  Group,
  isEverythingSelected,
  Row,
  SelectionStatus,
} from "./GroupedGrid";
import { useMemo } from "react";
import { transparentize } from "polished";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface TableRowProps {
  columns: Column[];
  rows: Map<string, Row>;
  groups: Map<string, Group>;
  rowOrGroup: Row | Group;
  id: string;
  isGroup: boolean;
  selectable: boolean;
  selectedRowIds: string[];
  setSelectedRowIds: (selectedRowIds: string[]) => void;
  depth?: number;
  expandedGroups: Map<string, Group>;
  setExpandedGroups: React.Dispatch<React.SetStateAction<Map<string, Group>>>;
  lastRow?: boolean;
}

function isRowSelected(rowId: string, selectedRowIds: string[]) {
  return selectedRowIds.includes(rowId);
}

function isGroupSelected(
  group: Group,
  selectedRowIds: string[]
): SelectionStatus {
  if (group.rows.size === 0) {
    return isEverythingSelected(
      group.groups,
      group.rows,
      selectedRowIds.filter((id) => getAllChildRowKeys(group).includes(id))
    );
  }

  let anyRowSelected = false;
  let allRowsSelected = true;
  for (let rowId of Array.from(group.rows.keys())) {
    const selected = isRowSelected(rowId, selectedRowIds);
    anyRowSelected = anyRowSelected || selected;
    allRowsSelected = allRowsSelected && selected;
    if (anyRowSelected && !allRowsSelected) return "partiallySelected";
  }
  let selectionStatus: SelectionStatus = allRowsSelected
    ? "fullySelected"
    : "notSelected";

  let anyGroupSelected = false;
  let allGroupsSelected = true;
  for (let subGroup of Array.from(group.groups.values())) {
    const selected = isGroupSelected(subGroup, selectedRowIds);
    anyGroupSelected = anyGroupSelected || selected !== "notSelected";
    allGroupsSelected = allGroupsSelected && selected === "fullySelected";
    if (selectionStatus === "fullySelected" && !allGroupsSelected)
      return "partiallySelected";
    if (selectionStatus === "notSelected" && anyGroupSelected)
      return "partiallySelected";
  }
  if (allGroupsSelected && allRowsSelected) return "fullySelected";
  return "notSelected";
}

function getAllChildrenKeys(group: Group) {
  const children = [
    ...Array.from(group.rows.keys()),
    ...Array.from(group.groups.keys()),
  ];
  for (let subGroup of Array.from(group.groups.values())) {
    children.push(...getAllChildrenKeys(subGroup));
  }
  return children;
}

const TableRow = ({
  columns,
  rows,
  groups,
  rowOrGroup,
  id,
  isGroup,
  selectable,
  selectedRowIds,
  setSelectedRowIds,
  depth = 0,
  expandedGroups,
  setExpandedGroups,
  lastRow = false,
}: TableRowProps) => {
  const theme = useTheme();

  const preExpandColumns = columns.filter(
    (column) => !column.afterExpandColumn
  );
  const postExpandColumns = columns.filter(
    (column) => column.afterExpandColumn
  );

  const isSelected = useMemo(() => {
    return isGroup
      ? isGroupSelected(rowOrGroup as Group, selectedRowIds)
      : isRowSelected(id, selectedRowIds);
  }, [selectedRowIds]);

  const childRows = isGroup ? getAllChildRowKeys(rowOrGroup as Group) : [];

  function selectAllChildren() {
    setSelectedRowIds([...selectedRowIds, ...childRows]);
  }

  function deselectAllChildren() {
    setSelectedRowIds(selectedRowIds.filter((id) => !childRows.includes(id)));
  }

  function handleExpandClick() {
    if (expandedGroups && setExpandedGroups) {
      if (expandedGroups.has(id)) {
        setExpandedGroups((prev) => {
          const newExpandedGroups = new Map(prev);
          newExpandedGroups.delete(id);
          const children = getAllChildrenKeys(rowOrGroup as Group);
          children.forEach((childId) => {
            newExpandedGroups.delete(childId);
          });
          return newExpandedGroups;
        });
      } else {
        setExpandedGroups((prev) => new Map(prev.set(id, rowOrGroup as Group)));
      }
    }
  }

  return (
    <Box
      sx={{
        p: 0.5,
        display: "grid",
        gridColumnStart: 1,
        gridColumnEnd: columns.length + (selectable ? 3 : 2),
        gridTemplateColumns: "subgrid",
        alignItems: "center",
        borderBottom: lastRow ? "none" : "1px solid",
        borderBottomColor: "divider",
        backgroundColor:
          isSelected !== "notSelected" && isSelected !== false
            ? transparentize(0.8, theme.palette.primary.main)
            : "transparent",
        backgroundImage: `linear-gradient(${
          theme.palette.mode === "dark"
            ? `rgba(255,255,255,${0.025 * depth})`
            : `rgba(0,0,0,${0.025 * depth})`
        }, ${
          theme.palette.mode === "dark"
            ? `rgba(255,255,255,${0.025 * depth})`
            : `rgba(0,0,0,${0.025 * depth})`
        })`,
      }}
    >
      {selectable && (
        <Box sx={{ gridColumn: 1 }}>
          <Checkbox
            indeterminate={isSelected === "partiallySelected"}
            checked={isSelected === "fullySelected" || isSelected === true}
            onChange={() => {
              if (isGroup) {
                isSelected === "notSelected"
                  ? selectAllChildren()
                  : deselectAllChildren();
              } else {
                isSelected
                  ? setSelectedRowIds(
                      selectedRowIds.filter((rowId) => rowId !== id)
                    )
                  : setSelectedRowIds([...selectedRowIds, id]);
              }
            }}
          />
        </Box>
      )}
      {preExpandColumns.map((column, index) => {
        if (column.renderCell) {
          return (
            <Box
              key={index}
              sx={{
                width: column.width || "auto",
              }}
            >
              {column.renderCell(rowOrGroup, rows, groups)}
            </Box>
          );
        }
        return (
          <Typography
            sx={{
              gridColumn:
                preExpandColumns.indexOf(column) + (selectable ? 2 : 1),
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            variant="body2"
            key={index}
          >
            {column.valueFormatter
              ? column.valueFormatter(String(rowOrGroup.data[column.field]))
              : String(rowOrGroup.data[column.field]) || ""}
          </Typography>
        );
      })}
      {isGroup ? (
        <IconButton
          sx={{ gridColumn: preExpandColumns.length + (selectable ? 2 : 1) }}
          onClick={handleExpandClick}
        >
          <ExpandMoreIcon
            sx={{
              transform: expandedGroups.has(id) ? "rotate(180deg)" : "",
            }}
          />
        </IconButton>
      ) : (
        <Box
          sx={{ gridColumn: preExpandColumns.length + (selectable ? 2 : 1) }}
        />
      )}
      {postExpandColumns.map((column, index) => {
        if (column.renderCell) {
          return (
            <Box
              key={index}
              sx={{
                width: column.width || "auto",
              }}
            >
              {column.renderCell(rowOrGroup, rows, groups)}
            </Box>
          );
        }
        return (
          <Typography
            sx={{
              gridColumn:
                preExpandColumns.length +
                postExpandColumns.indexOf(column) +
                (selectable ? 3 : 2),
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            variant="body2"
            key={index}
          >
            {column.valueFormatter
              ? column.valueFormatter(String(rowOrGroup.data[column.field]))
              : String(rowOrGroup.data[column.field]) || ""}
          </Typography>
        );
      })}
    </Box>
  );
};

export default TableRow;
