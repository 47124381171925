import * as React from "react";
import {
  useLazySourcesSyncWebsiteSourceGetQuery,
  useSourcesDeleteSourcesPostMutation,
  useSourcesGetSourcesGetQuery,
} from "../../../../state/layerApi";
import EditSourceModal from "./EditSourceModal";
import { Source } from "../../../../shared/types";
import { SourcesTableColumns } from "./SourcesTableColumns";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import { useDispatch } from "react-redux";
import { addSyncingSource } from "../../../../state/syncingSourcesSlice";
import { DataGrid, GridRowId } from "@mui/x-data-grid";
import SourcesTableToolbar from "./SourcesTableToolbar";

export default function SourcesTable() {
  const dispatch = useDispatch();

  const { data: sources } = useSourcesGetSourcesGetQuery();
  const [deleteSources] = useSourcesDeleteSourcesPostMutation();
  const [syncSource] = useLazySourcesSyncWebsiteSourceGetQuery();

  const { addMessage } = useSnackbarContext();

  const [editSource, setEditSource] = React.useState<Source | undefined>(
    undefined
  );

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [deleteRows, setDeleteRows] = React.useState<number[]>([]);

  const handleDeleteSourceRows = () => {
    const sourceIds = deleteRows.map((id) => id as unknown as number);
    deleteSources({ deleteSources: { ids: sourceIds } })
      .unwrap()
      .then(() => {
        setDeleteModalOpen(false);
        setDeleteRows([]);
        addMessage(
          deleteRows.length > 1 ? "Sources deleted" : "Source deleted",
          "success",
          30
        );
      })
      .catch((err) => {
        console.error(err);
        setDeleteModalOpen(false);
        setDeleteRows([]);
        addMessage(
          deleteRows.length > 1
            ? "Error deleting sources"
            : "Error deleting source",
          "error",
          60
        );
      });
  };

  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);

  return (
    <>
      <DataGrid
        rows={sources || []}
        columns={SourcesTableColumns(
          (id: number) => {
            const source = sources?.find((source) => source.id === id);
            setEditSource(source);
          },
          (ids: number[]) => {
            Promise.all(ids.map((id) => syncSource({ sourceId: id })))
              .then(() => {
                dispatch(addSyncingSource(ids));
              })
              .catch((err) => {
                console.error(err);
                addMessage("Error syncing sources", "error", 60);
              });
          },
          (ids: number[]) => {
            setDeleteRows(ids);
            setDeleteModalOpen(true);
          }
        )}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnResize
        disableColumnSelector
        pageSizeOptions={[10, 25, 50, 100]}
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRowIds([...newSelection]);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              created_at: false,
              collection_id: false,
            },
          },
          sorting: {
            sortModel: [{ field: "updated_at", sort: "desc" }],
          },
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
        slots={{
          toolbar: () => (
            <SourcesTableToolbar
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
              sources={sources || []}
            />
          ),
        }}
      />
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          deleteRows.length > 1 ? deleteRows.length + " Sources" : "1 Source"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteSourceRows}
      />
      <EditSourceModal
        source={editSource}
        modalOpen={editSource !== undefined}
        handleClose={() => setEditSource(undefined)}
      />
    </>
  );
}
