import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ModalDialog from "../../../shared/ModalDialog";
import { useToolsCreateToolPostMutation } from "../../../state/layerApi";
import ToolForm, { ToolFormValues } from "./ToolForm";

interface AddToolModalProps {
  modalOpen: boolean;
  handleClose: () => void;
}

const AddToolModal = ({ modalOpen, handleClose }: AddToolModalProps) => {
  const { addMessage } = useSnackbarContext();

  const [addTool] = useToolsCreateToolPostMutation();

  async function handleAddTool(values: ToolFormValues) {
    try {
      addTool({
        toolCreate: {
          name: values.name,
          description: values.description,
          method: values.method || "GET",
          url: values.url,
          parameters: {
            properties: {
              path: values.path
                ? { properties: JSON.parse(values.path) }
                : null,
              body: values.body
                ? { properties: JSON.parse(values.body) }
                : null,
              query: values.query
                ? { properties: JSON.parse(values.query) }
                : null,
              auth: values.auth
                ? { properties: JSON.parse(values.auth) }
                : null,
            },
          },
        },
      })
        .unwrap()
        .then(() => {
          addMessage("Tool added", "success");
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          addMessage("Failed to add tool", "error");
        });
    } catch (err) {
      console.error(err);
      addMessage("Failed to add tool", "error");
    }
  }

  return (
    <ModalDialog
      label="Add Tool"
      modalOpen={modalOpen}
      handleClose={handleClose}
      hasDefaultWidth
      defaultWidth={600}
    >
      <ToolForm onSubmit={handleAddTool} submitButtonLabel="Add Tool" />
    </ModalDialog>
  );
};

export default AddToolModal;
