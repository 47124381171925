import UploadFileIcon from "@mui/icons-material/UploadFile";
import Sources from "../components/Dashboard/Sources/Sources";
import HubIcon from "@mui/icons-material/Hub";
import VSCodeExtension from "../components/Dashboard/Deployments/VSCodeExtension/VSCodeExtension";
import React from "react";
import Analytics from "../components/Dashboard/analytics/Analytics";
import BarChartIcon from "@mui/icons-material/BarChart";
import WebWidget from "../components/Dashboard/Deployments/WebWidget";
import TuneIcon from "@mui/icons-material/Tune";
import ConstructionIcon from "@mui/icons-material/Construction";
import RAGConfiguration from "../components/Dashboard/RAGConfiguration/RAGConfiguration";
import Tools from "../components/Dashboard/Tools/Tools";

export interface PageProps {
  name: string;
  description: string;
  icon: React.JSX.Element;
  children: React.ReactNode;
  path: string;
  pageTitle: string;
  parent?: string;
}

const pageProps: PageProps[] = [
  {
    name: "Sources",
    description:
      "Add & manage the Sources your extensions reference to assist developers integrating your product.",
    icon: <UploadFileIcon />,
    children: <Sources />,
    path: "/sources",
    pageTitle: "Layer - Sources",
  },
  {
    name: "Tools",
    description:
      "Configure your API endpoints to be accessible from your deployed extensions.",
    icon: <ConstructionIcon />,
    children: <Tools />,
    path: "/tools",
    pageTitle: "Layer - Tools",
  },
  {
    name: "Analytics",
    description:
      "Get analytics on how developers are using your Integration Expert.",
    icon: <BarChartIcon />,
    children: <Analytics />,
    path: "/analytics",
    pageTitle: "Layer - Analytics",
  },
  {
    name: "VS Code Extension",
    description:
      "Adjust how your Integration Expert extension will show up in VSCode’s marketplace.",
    icon: <HubIcon />,
    children: <VSCodeExtension />,
    path: "/deployments/vscode",
    pageTitle: "Layer - VS Code Extension Deployment",
    parent: "Deployments",
  },
  {
    name: "Website Widget",
    description: "Adjust how your website widget appears.",
    icon: <HubIcon />,
    children: <WebWidget />,
    path: "/deployments/web-widget",
    pageTitle: "Layer - Website Widget Deployment",
    parent: "Deployments",
  },
  {
    name: "RAG Configuration",
    description:
      "Make adjustments to our RAG system to optimize responses for your Integration Expert deployments.",
    icon: <TuneIcon />,
    children: <RAGConfiguration />,
    path: "/rag-configuration",
    pageTitle: "Layer - RAG Configuration",
  },
];

export default pageProps;
