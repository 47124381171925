import { Box, Button } from "@mui/material";
import {
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DeleteConfirmationModal from "../../../shared/DeleteConfirmationModal";
import AddToolModal from "./AddToolModal";
import { useToolsDeleteToolDeleteMutation } from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";

interface ToolsTableToolbarProps {
  selectedRowIds: GridRowSelectionModel;
}

const ToolsTableToolbar = ({ selectedRowIds }: ToolsTableToolbarProps) => {
  const { addMessage } = useSnackbarContext();

  const [deleteTool, { isLoading }] = useToolsDeleteToolDeleteMutation();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  function handleDeleteTools() {
    Promise.all(
      selectedRowIds.map((id) => deleteTool({ toolId: id as number }))
    )
      .then(() => {
        setDeleteModalOpen(false);
        addMessage("Tools deleted", "success");
      })
      .catch((err) => {
        console.error(err);
        setDeleteModalOpen(false);
        addMessage("Failed to delete tools", "error");
      });
  }

  return (
    <>
      <GridToolbarContainer>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
            p: "12px",
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              height: "48px",
            }}
          >
            <Button
              variant="outlined"
              disabled={selectedRowIds.length === 0 || isLoading}
              color="error"
              onClick={() => setDeleteModalOpen(true)}
              sx={{ minWidth: 0, width: "56px", height: "48px" }}
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() => setAddModalOpen(true)}
              sx={{ minWidth: 0, width: "56px", height: "48px" }}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          selectedRowIds.length > 1
            ? selectedRowIds.length + " Tools"
            : "1 Tool"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteTools}
      />
      <AddToolModal
        modalOpen={addModalOpen}
        handleClose={() => setAddModalOpen(false)}
      />
    </>
  );
};

export default ToolsTableToolbar;
