import {Box, IconButton, TextField, Typography} from "@mui/material";
import * as React from "react";
import {sendPasswordResetEmail} from "firebase/auth";
import {auth} from "../../../clients/firebase";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbarContext} from "../../../contexts/SnackbarContext";

const ResetPassword = () => {
    const navigate = useNavigate();
    const {addMessage} = useSnackbarContext();

    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        const nextEmail = e.currentTarget.value;
        setEmail(nextEmail);
        if (e.currentTarget.validity.valid) {
            setEmailError(undefined);
        } else {
            setEmailError("Invalid email address");
        }
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (loading) return;

        setLoading(true);
        if (emailError === undefined) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    addMessage("Password reset link sent to your email!", "success", 60);
                })
                .catch(err => {
                    console.error(err);
                    addMessage("Could not send reset link at this time.", "error", 60);
                });
        } else {
            addMessage("Could not send reset link at this time.", "error", 60);
            console.error("Could not send reset link at this time");
        }
        setLoading(false);
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                }}
            >
                <IconButton onClick={() => navigate("/login")}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h2">Reset Password</Typography>
            </Box>
            <Box
                component={"form"}
                onSubmit={handleSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <TextField
                    required
                    label={"Email"}
                    variant={"filled"}
                    autoComplete={"off"}
                    type={"email"}
                    onChange={handleEmailChange}
                    error={emailError !== undefined}
                    helperText={emailError}
                />
                <LoadingButton
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    loading={loading}
                >
                    <Typography variant="button">Send Password Reset Link</Typography>
                </LoadingButton>
            </Box>
        </>
    );
};

export default ResetPassword;
