import { Snackbar } from "@mui/material";
import * as React from "react";
import {
  layerApi,
  useLazySourcesGetSyncWebsiteStatusGetQuery,
  useSourcesGetMultiSyncWebsiteStatusPostMutation,
  useSourcesSyncMultiWebsiteSourcesPostMutation,
} from "../../../../state/layerApi";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { setSyncingSources } from "../../../../state/syncingSourcesSlice";

const SyncStatus = () => {
  const { addMessage } = useSnackbarContext();
  const dispatch = useDispatch();

  const syncingSourceIds = useSelector(
    (state: RootState) => state.syncingSources
  ).syncingSources;

  const [message, setMessage] = React.useState<string>("Sync Queued...");
  const [open, setOpen] = React.useState<boolean>(false);

  const [getSyncStatuses] = useSourcesGetMultiSyncWebsiteStatusPostMutation();

  React.useEffect(() => {
    if (syncingSourceIds.length === 0) return;

    setMessage("Syncing Sources");
    setOpen(true);

    const interval = setInterval(async () => {
      const completedSources: number[] = [];

      await getSyncStatuses({ syncMultiSiteRequest: { ids: syncingSourceIds } })
        .unwrap()
        .then(({ statuses }) => {
          syncingSourceIds.forEach((sourceId) => {
            if (!statuses[sourceId]) {
              completedSources.push(sourceId);
            } else if (
              statuses[sourceId] === "DONE" ||
              statuses[sourceId] === null
            ) {
              completedSources.push(sourceId);
            }
          });
        });

      if (completedSources.length === syncingSourceIds.length) {
        setOpen(false);
        addMessage("Sync Complete", "success");
        dispatch(setSyncingSources({ syncingSources: [] }));
        dispatch(layerApi.util.invalidateTags(["sources"]));
      } else {
        dispatch(
          setSyncingSources({
            syncingSources: syncingSourceIds.filter(
              (id) => !completedSources.includes(id)
            ),
          })
        );
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [syncingSourceIds]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      message={message}
      autoHideDuration={3000}
      ContentProps={{
        sx: {
          display: "block",
          textAlign: "center",
        },
      }}
    />
  );
};

export default SyncStatus;
