import { emptyApi as api } from "./emptyApi";
export const addTagTypes = [
  "admin",
  "chat",
  "collections",
  "crawls",
  "customers",
  "embeds",
  "extensions",
  "health",
  "rag config",
  "sources",
  "tasks",
  "tools",
  "web_widgets",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      adminLoginPost: build.mutation<
        AdminLoginPostApiResponse,
        AdminLoginPostApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/token`,
          method: "POST",
          body: queryArg.bodyAdminLoginPost,
        }),
        invalidatesTags: ["admin"],
      }),
      adminGetAllCustomersGet: build.query<
        AdminGetAllCustomersGetApiResponse,
        AdminGetAllCustomersGetApiArg
      >({
        query: () => ({ url: `/admin/customers` }),
        providesTags: ["admin"],
      }),
      adminCreateCustomerPost: build.mutation<
        AdminCreateCustomerPostApiResponse,
        AdminCreateCustomerPostApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers`,
          method: "POST",
          body: queryArg.customerCreateAdmin,
        }),
        invalidatesTags: ["admin"],
      }),
      adminGetCustomerHashedIdGet: build.query<
        AdminGetCustomerHashedIdGetApiResponse,
        AdminGetCustomerHashedIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers/hash/${queryArg.customerId}`,
        }),
        providesTags: ["admin"],
      }),
      adminGetCustomerGet: build.query<
        AdminGetCustomerGetApiResponse,
        AdminGetCustomerGetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers/${queryArg.customerId}`,
        }),
        providesTags: ["admin"],
      }),
      adminUpdateCustomerPatch: build.mutation<
        AdminUpdateCustomerPatchApiResponse,
        AdminUpdateCustomerPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers/${queryArg.customerId}`,
          method: "PATCH",
          body: queryArg.customerUpdateAdmin,
        }),
        invalidatesTags: ["admin"],
      }),
      adminDeleteCustomerDelete: build.mutation<
        AdminDeleteCustomerDeleteApiResponse,
        AdminDeleteCustomerDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers/${queryArg.customerId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["admin"],
      }),
      adminGetCustomerFirebaseTokenGet: build.query<
        AdminGetCustomerFirebaseTokenGetApiResponse,
        AdminGetCustomerFirebaseTokenGetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/customers/${queryArg.customerId}/firebase_token`,
        }),
        providesTags: ["admin"],
      }),
      adminGetAllExtensionsGet: build.query<
        AdminGetAllExtensionsGetApiResponse,
        AdminGetAllExtensionsGetApiArg
      >({
        query: () => ({ url: `/admin/extensions` }),
        providesTags: ["admin"],
      }),
      adminDownloadExtensionObjectGet: build.query<
        AdminDownloadExtensionObjectGetApiResponse,
        AdminDownloadExtensionObjectGetApiArg
      >({
        query: (queryArg) => ({
          url: `/admin/extensions/${queryArg.extensionId}/download/${queryArg.specialObjectName}`,
        }),
        providesTags: ["admin"],
      }),
      adminGetAllMessageLogsGet: build.query<
        AdminGetAllMessageLogsGetApiResponse,
        AdminGetAllMessageLogsGetApiArg
      >({
        query: () => ({ url: `/admin/message_logs` }),
        providesTags: ["admin"],
      }),
      adminSyncOutdatedSourcesGet: build.query<
        AdminSyncOutdatedSourcesGetApiResponse,
        AdminSyncOutdatedSourcesGetApiArg
      >({
        query: () => ({ url: `/admin/sync` }),
        providesTags: ["admin"],
      }),
      chatVscodeCompletionPost: build.mutation<
        ChatVscodeCompletionPostApiResponse,
        ChatVscodeCompletionPostApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/vscode`,
          method: "POST",
          body: queryArg.vsCodeCompletionRequest,
        }),
        invalidatesTags: ["chat"],
      }),
      chatGetCompletionPost: build.mutation<
        ChatGetCompletionPostApiResponse,
        ChatGetCompletionPostApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/completion`,
          method: "POST",
          body: queryArg.completionRequest,
        }),
        invalidatesTags: ["chat"],
      }),
      chatSearchPost: build.mutation<
        ChatSearchPostApiResponse,
        ChatSearchPostApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/search`,
          method: "POST",
          body: queryArg.searchRequest,
        }),
        invalidatesTags: ["chat"],
      }),
      chatStreamCompletionPost: build.mutation<
        ChatStreamCompletionPostApiResponse,
        ChatStreamCompletionPostApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/completion/stream`,
          method: "POST",
          body: queryArg.streamedCompletionRequest,
        }),
        invalidatesTags: ["chat"],
      }),
      chatCopilotEndpointPost: build.mutation<
        ChatCopilotEndpointPostApiResponse,
        ChatCopilotEndpointPostApiArg
      >({
        query: (queryArg) => ({
          url: `/chat/copilot/${queryArg.apiKey}`,
          method: "POST",
        }),
        invalidatesTags: ["chat"],
      }),
      chatGetMessageLogsGet: build.query<
        ChatGetMessageLogsGetApiResponse,
        ChatGetMessageLogsGetApiArg
      >({
        query: () => ({ url: `/chat/message_logs` }),
        providesTags: ["chat"],
      }),
      collectionsGetAllCollectionsGet: build.query<
        CollectionsGetAllCollectionsGetApiResponse,
        CollectionsGetAllCollectionsGetApiArg
      >({
        query: () => ({ url: `/collections/` }),
        providesTags: ["collections"],
      }),
      collectionsGetCollectionGet: build.query<
        CollectionsGetCollectionGetApiResponse,
        CollectionsGetCollectionGetApiArg
      >({
        query: (queryArg) => ({ url: `/collections/${queryArg.collectionId}` }),
        providesTags: ["collections"],
      }),
      crawlsGetCrawlSiteJobIdsGet: build.query<
        CrawlsGetCrawlSiteJobIdsGetApiResponse,
        CrawlsGetCrawlSiteJobIdsGetApiArg
      >({
        query: () => ({ url: `/crawls/site` }),
        providesTags: ["crawls"],
      }),
      crawlsCrawlWebsitePost: build.mutation<
        CrawlsCrawlWebsitePostApiResponse,
        CrawlsCrawlWebsitePostApiArg
      >({
        query: (queryArg) => ({
          url: `/crawls/site`,
          method: "POST",
          body: queryArg.crawlSiteRequest,
        }),
        invalidatesTags: ["crawls"],
      }),
      crawlsGetCrawlSiteProgressGet: build.query<
        CrawlsGetCrawlSiteProgressGetApiResponse,
        CrawlsGetCrawlSiteProgressGetApiArg
      >({
        query: (queryArg) => ({ url: `/crawls/site/${queryArg.jobKey}` }),
        providesTags: ["crawls"],
      }),
      crawlsClearCrawlDelete: build.mutation<
        CrawlsClearCrawlDeleteApiResponse,
        CrawlsClearCrawlDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/crawls/site/${queryArg.jobKey}`,
          method: "DELETE",
        }),
        invalidatesTags: ["crawls"],
      }),
      crawlsCrawlGithubPost: build.mutation<
        CrawlsCrawlGithubPostApiResponse,
        CrawlsCrawlGithubPostApiArg
      >({
        query: (queryArg) => ({
          url: `/crawls/github`,
          method: "POST",
          body: queryArg.crawlGitHubRequest,
          params: { token: queryArg.token },
        }),
        invalidatesTags: ["crawls"],
      }),
      customersGetCustomerGet: build.query<
        CustomersGetCustomerGetApiResponse,
        CustomersGetCustomerGetApiArg
      >({
        query: () => ({ url: `/customers/` }),
        providesTags: ["customers"],
      }),
      customersCreateCustomerPost: build.mutation<
        CustomersCreateCustomerPostApiResponse,
        CustomersCreateCustomerPostApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/`,
          method: "POST",
          body: queryArg.customerCreate,
        }),
        invalidatesTags: ["customers"],
      }),
      customersGetCustomerTransferringGet: build.query<
        CustomersGetCustomerTransferringGetApiResponse,
        CustomersGetCustomerTransferringGetApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/transferring/${queryArg.customerHash}`,
        }),
        providesTags: ["customers"],
      }),
      customersUpdateCustomerEmailPatch: build.mutation<
        CustomersUpdateCustomerEmailPatchApiResponse,
        CustomersUpdateCustomerEmailPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/${queryArg.customerHash}`,
          method: "PATCH",
        }),
        invalidatesTags: ["customers"],
      }),
      customersCheckNameAvailabilityGet: build.query<
        CustomersCheckNameAvailabilityGetApiResponse,
        CustomersCheckNameAvailabilityGetApiArg
      >({
        query: (queryArg) => ({
          url: `/customers/name/${queryArg.companyName}`,
        }),
        providesTags: ["customers"],
      }),
      embedsGetEmbedJobProgressGet: build.query<
        EmbedsGetEmbedJobProgressGetApiResponse,
        EmbedsGetEmbedJobProgressGetApiArg
      >({
        query: () => ({ url: `/embeds/` }),
        providesTags: ["embeds"],
      }),
      extensionsGetExtensionsGet: build.query<
        ExtensionsGetExtensionsGetApiResponse,
        ExtensionsGetExtensionsGetApiArg
      >({
        query: () => ({ url: `/extensions/` }),
        providesTags: ["extensions"],
      }),
      extensionsUpdateExtensionPut: build.mutation<
        ExtensionsUpdateExtensionPutApiResponse,
        ExtensionsUpdateExtensionPutApiArg
      >({
        query: (queryArg) => ({
          url: `/extensions/${queryArg.extensionId}`,
          method: "PUT",
          body: queryArg.extensionUpdate,
        }),
        invalidatesTags: ["extensions"],
      }),
      extensionsDeleteExtensionDelete: build.mutation<
        ExtensionsDeleteExtensionDeleteApiResponse,
        ExtensionsDeleteExtensionDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/extensions/${queryArg.extensionId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["extensions"],
      }),
      extensionsPublishExtensionGet: build.query<
        ExtensionsPublishExtensionGetApiResponse,
        ExtensionsPublishExtensionGetApiArg
      >({
        query: (queryArg) => ({
          url: `/extensions/${queryArg.extensionId}/publish/${queryArg.templateName}`,
        }),
        providesTags: ["extensions"],
      }),
      extensionsDownloadObjectGet: build.query<
        ExtensionsDownloadObjectGetApiResponse,
        ExtensionsDownloadObjectGetApiArg
      >({
        query: (queryArg) => ({
          url: `/extensions/${queryArg.extensionId}/download/${queryArg.specialObjectName}`,
        }),
        providesTags: ["extensions"],
      }),
      extensionsListTemplatesGet: build.query<
        ExtensionsListTemplatesGetApiResponse,
        ExtensionsListTemplatesGetApiArg
      >({
        query: () => ({ url: `/extensions/templates` }),
        providesTags: ["extensions"],
      }),
      healthAllChecksGet: build.query<
        HealthAllChecksGetApiResponse,
        HealthAllChecksGetApiArg
      >({
        query: () => ({ url: `/health/` }),
        providesTags: ["health"],
      }),
      healthDatabaseCheckGet: build.query<
        HealthDatabaseCheckGetApiResponse,
        HealthDatabaseCheckGetApiArg
      >({
        query: () => ({ url: `/health/database` }),
        providesTags: ["health"],
      }),
      healthMarketplaceCheckGet: build.query<
        HealthMarketplaceCheckGetApiResponse,
        HealthMarketplaceCheckGetApiArg
      >({
        query: () => ({ url: `/health/marketplace` }),
        providesTags: ["health"],
      }),
      healthStorageCheckGet: build.query<
        HealthStorageCheckGetApiResponse,
        HealthStorageCheckGetApiArg
      >({
        query: () => ({ url: `/health/storage` }),
        providesTags: ["health"],
      }),
      ragConfigGetRagConfigurationGet: build.query<
        RagConfigGetRagConfigurationGetApiResponse,
        RagConfigGetRagConfigurationGetApiArg
      >({
        query: () => ({ url: `/rag_config/` }),
        providesTags: ["rag config"],
      }),
      ragConfigUpdateRagConfigurationPut: build.mutation<
        RagConfigUpdateRagConfigurationPutApiResponse,
        RagConfigUpdateRagConfigurationPutApiArg
      >({
        query: (queryArg) => ({
          url: `/rag_config/`,
          method: "PUT",
          body: queryArg.ragConfigurationUpdate,
        }),
        invalidatesTags: ["rag config"],
      }),
      sourcesGetSourcesGet: build.query<
        SourcesGetSourcesGetApiResponse,
        SourcesGetSourcesGetApiArg
      >({
        query: () => ({ url: `/sources/` }),
        providesTags: ["sources"],
      }),
      sourcesGetSourceGet: build.query<
        SourcesGetSourceGetApiResponse,
        SourcesGetSourceGetApiArg
      >({
        query: (queryArg) => ({ url: `/sources/${queryArg.sourceId}` }),
        providesTags: ["sources"],
      }),
      sourcesDeleteSourceDelete: build.mutation<
        SourcesDeleteSourceDeleteApiResponse,
        SourcesDeleteSourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/${queryArg.sourceId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesGetSupportedTypesGet: build.query<
        SourcesGetSupportedTypesGetApiResponse,
        SourcesGetSupportedTypesGetApiArg
      >({
        query: () => ({ url: `/sources/document/types` }),
        providesTags: ["sources"],
      }),
      sourcesGetEmbeddingsGet: build.query<
        SourcesGetEmbeddingsGetApiResponse,
        SourcesGetEmbeddingsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/embeddings/${queryArg.sourceId}`,
        }),
        providesTags: ["sources"],
      }),
      sourcesCreateDocumentSourcePost: build.mutation<
        SourcesCreateDocumentSourcePostApiResponse,
        SourcesCreateDocumentSourcePostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/document`,
          method: "POST",
          body: queryArg.documentSourceCreate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesCreateSuggestionSourcePost: build.mutation<
        SourcesCreateSuggestionSourcePostApiResponse,
        SourcesCreateSuggestionSourcePostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/suggestion`,
          method: "POST",
          body: queryArg.suggestionSourceCreate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesCreateWebsiteSourcesPost: build.mutation<
        SourcesCreateWebsiteSourcesPostApiResponse,
        SourcesCreateWebsiteSourcesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website`,
          method: "POST",
          body: queryArg.sources,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesCreateGithubSourcesPost: build.mutation<
        SourcesCreateGithubSourcesPostApiResponse,
        SourcesCreateGithubSourcesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/github`,
          method: "POST",
          body: queryArg.gitHubSourcesCreate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesCreateSourcesFromLlmsTxtPost: build.mutation<
        SourcesCreateSourcesFromLlmsTxtPostApiResponse,
        SourcesCreateSourcesFromLlmsTxtPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/llms`,
          method: "POST",
          body: queryArg.documentSourceCreate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesUpdateDocumentSourcePatch: build.mutation<
        SourcesUpdateDocumentSourcePatchApiResponse,
        SourcesUpdateDocumentSourcePatchApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/document/${queryArg.sourceId}`,
          method: "PATCH",
          body: queryArg.documentSourceUpdate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesUpdateSuggestionSourcePatch: build.mutation<
        SourcesUpdateSuggestionSourcePatchApiResponse,
        SourcesUpdateSuggestionSourcePatchApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/suggestion/${queryArg.sourceId}`,
          method: "PATCH",
          body: queryArg.suggestionSourceUpdate,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesSyncWebsiteSourceGet: build.query<
        SourcesSyncWebsiteSourceGetApiResponse,
        SourcesSyncWebsiteSourceGetApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website/sync/${queryArg.sourceId}`,
        }),
        providesTags: ["sources"],
      }),
      sourcesClearSyncWebsiteStatusDelete: build.mutation<
        SourcesClearSyncWebsiteStatusDeleteApiResponse,
        SourcesClearSyncWebsiteStatusDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website/sync/${queryArg.sourceId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesSyncMultiWebsiteSourcesPost: build.mutation<
        SourcesSyncMultiWebsiteSourcesPostApiResponse,
        SourcesSyncMultiWebsiteSourcesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website/sync`,
          method: "POST",
          body: queryArg.syncMultiSiteRequest,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesGetSyncWebsiteStatusGet: build.query<
        SourcesGetSyncWebsiteStatusGetApiResponse,
        SourcesGetSyncWebsiteStatusGetApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website/sync/${queryArg.sourceId}/status`,
        }),
        providesTags: ["sources"],
      }),
      sourcesGetMultiSyncWebsiteStatusPost: build.mutation<
        SourcesGetMultiSyncWebsiteStatusPostApiResponse,
        SourcesGetMultiSyncWebsiteStatusPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/website/sync/status`,
          method: "POST",
          body: queryArg.syncMultiSiteRequest,
        }),
        invalidatesTags: ["sources"],
      }),
      sourcesDeleteSourcesPost: build.mutation<
        SourcesDeleteSourcesPostApiResponse,
        SourcesDeleteSourcesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/sources/delete`,
          method: "POST",
          body: queryArg.deleteSources,
        }),
        invalidatesTags: ["sources"],
      }),
      tasksCrawlSitePost: build.mutation<
        TasksCrawlSitePostApiResponse,
        TasksCrawlSitePostApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/crawl_site`,
          method: "POST",
          body: queryArg.crawlSiteMessage,
        }),
        invalidatesTags: ["tasks"],
      }),
      tasksEmbedSitePost: build.mutation<
        TasksEmbedSitePostApiResponse,
        TasksEmbedSitePostApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/embed_site`,
          method: "POST",
          body: queryArg.embedMultiSiteMessage,
        }),
        invalidatesTags: ["tasks"],
      }),
      tasksEmbedGithubPost: build.mutation<
        TasksEmbedGithubPostApiResponse,
        TasksEmbedGithubPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/embed_github`,
          method: "POST",
          body: queryArg.embedGitHubMessage,
          params: { token: queryArg.token },
        }),
        invalidatesTags: ["tasks"],
      }),
      tasksSyncSitePost: build.mutation<
        TasksSyncSitePostApiResponse,
        TasksSyncSitePostApiArg
      >({
        query: (queryArg) => ({
          url: `/tasks/sync_site`,
          method: "POST",
          body: queryArg.syncSiteMessage,
        }),
        invalidatesTags: ["tasks"],
      }),
      toolsGetToolsGet: build.query<
        ToolsGetToolsGetApiResponse,
        ToolsGetToolsGetApiArg
      >({
        query: () => ({ url: `/tools/` }),
        providesTags: ["tools"],
      }),
      toolsCreateToolPost: build.mutation<
        ToolsCreateToolPostApiResponse,
        ToolsCreateToolPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/`,
          method: "POST",
          body: queryArg.toolCreate,
        }),
        invalidatesTags: ["tools"],
      }),
      toolsGetToolGet: build.query<
        ToolsGetToolGetApiResponse,
        ToolsGetToolGetApiArg
      >({
        query: (queryArg) => ({ url: `/tools/${queryArg.toolId}` }),
        providesTags: ["tools"],
      }),
      toolsUpdateToolPatch: build.mutation<
        ToolsUpdateToolPatchApiResponse,
        ToolsUpdateToolPatchApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/${queryArg.toolId}`,
          method: "PATCH",
          body: queryArg.toolUpdate,
        }),
        invalidatesTags: ["tools"],
      }),
      toolsDeleteToolDelete: build.mutation<
        ToolsDeleteToolDeleteApiResponse,
        ToolsDeleteToolDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/${queryArg.toolId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["tools"],
      }),
      toolsCallToolPost: build.mutation<
        ToolsCallToolPostApiResponse,
        ToolsCallToolPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/call`,
          method: "POST",
          body: queryArg.toolCall,
        }),
        invalidatesTags: ["tools"],
      }),
      toolsCallToolByIdPost: build.mutation<
        ToolsCallToolByIdPostApiResponse,
        ToolsCallToolByIdPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/call/${queryArg.toolId}`,
          method: "POST",
          body: queryArg.toolCall,
        }),
        invalidatesTags: ["tools"],
      }),
      toolsConvertOpenapiToToolCallPost: build.mutation<
        ToolsConvertOpenapiToToolCallPostApiResponse,
        ToolsConvertOpenapiToToolCallPostApiArg
      >({
        query: (queryArg) => ({
          url: `/tools/convert_openapi`,
          method: "POST",
          body: queryArg.bodyToolsConvertOpenapiToToolCallPost,
        }),
        invalidatesTags: ["tools"],
      }),
      webWidgetsGetWebWidgetsGet: build.query<
        WebWidgetsGetWebWidgetsGetApiResponse,
        WebWidgetsGetWebWidgetsGetApiArg
      >({
        query: () => ({ url: `/web_widgets/` }),
        providesTags: ["web_widgets"],
      }),
      webWidgetsUpdateWidgetPut: build.mutation<
        WebWidgetsUpdateWidgetPutApiResponse,
        WebWidgetsUpdateWidgetPutApiArg
      >({
        query: (queryArg) => ({
          url: `/web_widgets/${queryArg.webWidgetId}`,
          method: "PUT",
          body: queryArg.webWidgetUpdate,
        }),
        invalidatesTags: ["web_widgets"],
      }),
      webWidgetsDownloadLogoGet: build.query<
        WebWidgetsDownloadLogoGetApiResponse,
        WebWidgetsDownloadLogoGetApiArg
      >({
        query: (queryArg) => ({
          url: `/web_widgets/${queryArg.webWidgetId}/download-logo`,
        }),
        providesTags: ["web_widgets"],
      }),
      rootGet: build.query<RootGetApiResponse, RootGetApiArg>({
        query: () => ({ url: `/` }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as layerApi };
export type AdminLoginPostApiResponse =
  /** status 200 Successful Response */ Token;
export type AdminLoginPostApiArg = {
  bodyAdminLoginPost: BodyAdminLoginPost;
};
export type AdminGetAllCustomersGetApiResponse =
  /** status 200 Successful Response */ Customer[];
export type AdminGetAllCustomersGetApiArg = void;
export type AdminCreateCustomerPostApiResponse =
  /** status 200 Successful Response */ Customer;
export type AdminCreateCustomerPostApiArg = {
  customerCreateAdmin: CustomerCreateAdmin;
};
export type AdminGetCustomerHashedIdGetApiResponse =
  /** status 200 Successful Response */ CustomerHashedId;
export type AdminGetCustomerHashedIdGetApiArg = {
  customerId: number;
};
export type AdminGetCustomerGetApiResponse =
  /** status 200 Successful Response */ Customer;
export type AdminGetCustomerGetApiArg = {
  customerId: number;
};
export type AdminUpdateCustomerPatchApiResponse =
  /** status 200 Successful Response */ Customer;
export type AdminUpdateCustomerPatchApiArg = {
  customerId: number;
  customerUpdateAdmin: CustomerUpdateAdmin;
};
export type AdminDeleteCustomerDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type AdminDeleteCustomerDeleteApiArg = {
  customerId: number;
};
export type AdminGetCustomerFirebaseTokenGetApiResponse =
  /** status 200 Successful Response */ Token;
export type AdminGetCustomerFirebaseTokenGetApiArg = {
  customerId: number;
};
export type AdminGetAllExtensionsGetApiResponse =
  /** status 200 Successful Response */ Extension[];
export type AdminGetAllExtensionsGetApiArg = void;
export type AdminDownloadExtensionObjectGetApiResponse =
  /** status 200 Successful Response */ string;
export type AdminDownloadExtensionObjectGetApiArg = {
  extensionId: number;
  specialObjectName: string;
};
export type AdminGetAllMessageLogsGetApiResponse =
  /** status 200 Successful Response */ MessageLog[];
export type AdminGetAllMessageLogsGetApiArg = void;
export type AdminSyncOutdatedSourcesGetApiResponse =
  /** status 200 Successful Response */ any;
export type AdminSyncOutdatedSourcesGetApiArg = void;
export type ChatVscodeCompletionPostApiResponse =
  /** status 200 Successful Response */ PromptResponse;
export type ChatVscodeCompletionPostApiArg = {
  vsCodeCompletionRequest: VsCodeCompletionRequest;
};
export type ChatGetCompletionPostApiResponse =
  /** status 200 Successful Response */ CompletionResponse;
export type ChatGetCompletionPostApiArg = {
  completionRequest: CompletionRequest;
};
export type ChatSearchPostApiResponse =
  /** status 200 Successful Response */ SearchResponse;
export type ChatSearchPostApiArg = {
  searchRequest: SearchRequest;
};
export type ChatStreamCompletionPostApiResponse =
  /** status 200 Successful Response */ any;
export type ChatStreamCompletionPostApiArg = {
  streamedCompletionRequest: StreamedCompletionRequest;
};
export type ChatCopilotEndpointPostApiResponse =
  /** status 200 Successful Response */ any;
export type ChatCopilotEndpointPostApiArg = {
  apiKey: string;
};
export type ChatGetMessageLogsGetApiResponse =
  /** status 200 Successful Response */ MessageLog[];
export type ChatGetMessageLogsGetApiArg = void;
export type CollectionsGetAllCollectionsGetApiResponse =
  /** status 200 Successful Response */ Collection[];
export type CollectionsGetAllCollectionsGetApiArg = void;
export type CollectionsGetCollectionGetApiResponse =
  /** status 200 Successful Response */ Collection;
export type CollectionsGetCollectionGetApiArg = {
  collectionId: number;
};
export type CrawlsGetCrawlSiteJobIdsGetApiResponse =
  /** status 200 Successful Response */ CrawlJobKeys;
export type CrawlsGetCrawlSiteJobIdsGetApiArg = void;
export type CrawlsCrawlWebsitePostApiResponse =
  /** status 200 Successful Response */ CrawlResponse;
export type CrawlsCrawlWebsitePostApiArg = {
  crawlSiteRequest: CrawlSiteRequest;
};
export type CrawlsGetCrawlSiteProgressGetApiResponse =
  /** status 200 Successful Response */ CrawlSiteProgress;
export type CrawlsGetCrawlSiteProgressGetApiArg = {
  jobKey: string;
};
export type CrawlsClearCrawlDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type CrawlsClearCrawlDeleteApiArg = {
  jobKey: string;
};
export type CrawlsCrawlGithubPostApiResponse =
  /** status 200 Successful Response */ CrawlGitHubResponse;
export type CrawlsCrawlGithubPostApiArg = {
  token?: string | null;
  crawlGitHubRequest: CrawlGitHubRequest;
};
export type CustomersGetCustomerGetApiResponse =
  /** status 200 Successful Response */ Customer;
export type CustomersGetCustomerGetApiArg = void;
export type CustomersCreateCustomerPostApiResponse =
  /** status 200 Successful Response */ Customer;
export type CustomersCreateCustomerPostApiArg = {
  customerCreate: CustomerCreate;
};
export type CustomersGetCustomerTransferringGetApiResponse =
  /** status 200 Successful Response */ Customer;
export type CustomersGetCustomerTransferringGetApiArg = {
  customerHash: string;
};
export type CustomersUpdateCustomerEmailPatchApiResponse =
  /** status 200 Successful Response */ Customer;
export type CustomersUpdateCustomerEmailPatchApiArg = {
  customerHash: string;
};
export type CustomersCheckNameAvailabilityGetApiResponse =
  /** status 200 Successful Response */ NameCheck;
export type CustomersCheckNameAvailabilityGetApiArg = {
  companyName: string;
};
export type EmbedsGetEmbedJobProgressGetApiResponse =
  /** status 200 Successful Response */ EmbedJobsProgress;
export type EmbedsGetEmbedJobProgressGetApiArg = void;
export type ExtensionsGetExtensionsGetApiResponse =
  /** status 200 Successful Response */ Extension[];
export type ExtensionsGetExtensionsGetApiArg = void;
export type ExtensionsUpdateExtensionPutApiResponse =
  /** status 200 Successful Response */ Extension;
export type ExtensionsUpdateExtensionPutApiArg = {
  extensionId: number;
  extensionUpdate: ExtensionUpdate;
};
export type ExtensionsDeleteExtensionDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type ExtensionsDeleteExtensionDeleteApiArg = {
  extensionId: number;
};
export type ExtensionsPublishExtensionGetApiResponse =
  /** status 204 Successful Response */ void;
export type ExtensionsPublishExtensionGetApiArg = {
  extensionId: number;
  templateName: string;
};
export type ExtensionsDownloadObjectGetApiResponse =
  /** status 200 Successful Response */ string;
export type ExtensionsDownloadObjectGetApiArg = {
  extensionId: number;
  specialObjectName: string;
};
export type ExtensionsListTemplatesGetApiResponse =
  /** status 200 Successful Response */ ExtensionTemplateOptions;
export type ExtensionsListTemplatesGetApiArg = void;
export type HealthAllChecksGetApiResponse =
  /** status 200 Successful Response */ Health;
export type HealthAllChecksGetApiArg = void;
export type HealthDatabaseCheckGetApiResponse =
  /** status 200 Successful Response */ Health;
export type HealthDatabaseCheckGetApiArg = void;
export type HealthMarketplaceCheckGetApiResponse =
  /** status 200 Successful Response */ Health;
export type HealthMarketplaceCheckGetApiArg = void;
export type HealthStorageCheckGetApiResponse =
  /** status 200 Successful Response */ Health;
export type HealthStorageCheckGetApiArg = void;
export type RagConfigGetRagConfigurationGetApiResponse =
  /** status 200 Successful Response */ RagConfiguration;
export type RagConfigGetRagConfigurationGetApiArg = void;
export type RagConfigUpdateRagConfigurationPutApiResponse =
  /** status 200 Successful Response */ RagConfiguration;
export type RagConfigUpdateRagConfigurationPutApiArg = {
  ragConfigurationUpdate: RagConfigurationUpdate;
};
export type SourcesGetSourcesGetApiResponse =
  /** status 200 Successful Response */ (
    | DocumentSource
    | SuggestionSource
    | WebsiteSource
  )[];
export type SourcesGetSourcesGetApiArg = void;
export type SourcesGetSourceGetApiResponse =
  /** status 200 Successful Response */
    | DocumentSource
    | SuggestionSource
    | WebsiteSource;
export type SourcesGetSourceGetApiArg = {
  sourceId: number;
};
export type SourcesDeleteSourceDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type SourcesDeleteSourceDeleteApiArg = {
  sourceId: number;
};
export type SourcesGetSupportedTypesGetApiResponse =
  /** status 200 Successful Response */ DocumentSourceSupportedTypes;
export type SourcesGetSupportedTypesGetApiArg = void;
export type SourcesGetEmbeddingsGetApiResponse =
  /** status 200 Successful Response */ SourceEmbedding[];
export type SourcesGetEmbeddingsGetApiArg = {
  sourceId: number;
};
export type SourcesCreateDocumentSourcePostApiResponse =
  /** status 200 Successful Response */ DocumentSource;
export type SourcesCreateDocumentSourcePostApiArg = {
  documentSourceCreate: DocumentSourceCreate;
};
export type SourcesCreateSuggestionSourcePostApiResponse =
  /** status 200 Successful Response */ SuggestionSource;
export type SourcesCreateSuggestionSourcePostApiArg = {
  suggestionSourceCreate: SuggestionSourceCreate;
};
export type SourcesCreateWebsiteSourcesPostApiResponse =
  /** status 200 Successful Response */ EmbedJobKeys;
export type SourcesCreateWebsiteSourcesPostApiArg = {
  sources: WebsiteSourceCreate[];
};
export type SourcesCreateGithubSourcesPostApiResponse =
  /** status 200 Successful Response */ EmbedJobKeys;
export type SourcesCreateGithubSourcesPostApiArg = {
  gitHubSourcesCreate: GitHubSourcesCreate;
};
export type SourcesCreateSourcesFromLlmsTxtPostApiResponse =
  /** status 200 Successful Response */ SimpleDocumentSourceCreate[];
export type SourcesCreateSourcesFromLlmsTxtPostApiArg = {
  documentSourceCreate: DocumentSourceCreate;
};
export type SourcesUpdateDocumentSourcePatchApiResponse =
  /** status 200 Successful Response */ DocumentSource;
export type SourcesUpdateDocumentSourcePatchApiArg = {
  sourceId: number;
  documentSourceUpdate: DocumentSourceUpdate;
};
export type SourcesUpdateSuggestionSourcePatchApiResponse =
  /** status 200 Successful Response */ SuggestionSource;
export type SourcesUpdateSuggestionSourcePatchApiArg = {
  sourceId: number;
  suggestionSourceUpdate: SuggestionSourceUpdate;
};
export type SourcesSyncWebsiteSourceGetApiResponse =
  /** status 204 Successful Response */ void;
export type SourcesSyncWebsiteSourceGetApiArg = {
  sourceId: number;
};
export type SourcesClearSyncWebsiteStatusDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type SourcesClearSyncWebsiteStatusDeleteApiArg = {
  sourceId: number;
};
export type SourcesSyncMultiWebsiteSourcesPostApiResponse =
  /** status 204 Successful Response */ void;
export type SourcesSyncMultiWebsiteSourcesPostApiArg = {
  syncMultiSiteRequest: SyncMultiSiteRequest;
};
export type SourcesGetSyncWebsiteStatusGetApiResponse =
  /** status 200 Successful Response */ SyncSiteStatus;
export type SourcesGetSyncWebsiteStatusGetApiArg = {
  sourceId: number;
};
export type SourcesGetMultiSyncWebsiteStatusPostApiResponse =
  /** status 200 Successful Response */ SyncMultiSiteStatus;
export type SourcesGetMultiSyncWebsiteStatusPostApiArg = {
  syncMultiSiteRequest: SyncMultiSiteRequest;
};
export type SourcesDeleteSourcesPostApiResponse =
  /** status 204 Successful Response */ void;
export type SourcesDeleteSourcesPostApiArg = {
  deleteSources: DeleteSources;
};
export type TasksCrawlSitePostApiResponse =
  /** status 200 Successful Response */ any;
export type TasksCrawlSitePostApiArg = {
  crawlSiteMessage: CrawlSiteMessage;
};
export type TasksEmbedSitePostApiResponse =
  /** status 200 Successful Response */ any;
export type TasksEmbedSitePostApiArg = {
  embedMultiSiteMessage: EmbedMultiSiteMessage;
};
export type TasksEmbedGithubPostApiResponse =
  /** status 200 Successful Response */ any;
export type TasksEmbedGithubPostApiArg = {
  token?: string | null;
  embedGitHubMessage: EmbedGitHubMessage;
};
export type TasksSyncSitePostApiResponse =
  /** status 200 Successful Response */ any;
export type TasksSyncSitePostApiArg = {
  syncSiteMessage: SyncSiteMessage;
};
export type ToolsGetToolsGetApiResponse =
  /** status 200 Successful Response */ Tool[];
export type ToolsGetToolsGetApiArg = void;
export type ToolsCreateToolPostApiResponse =
  /** status 200 Successful Response */ Tool;
export type ToolsCreateToolPostApiArg = {
  toolCreate: ToolCreate;
};
export type ToolsGetToolGetApiResponse =
  /** status 200 Successful Response */ Tool;
export type ToolsGetToolGetApiArg = {
  toolId: number;
};
export type ToolsUpdateToolPatchApiResponse =
  /** status 200 Successful Response */ Tool;
export type ToolsUpdateToolPatchApiArg = {
  toolId: number;
  toolUpdate: ToolUpdate;
};
export type ToolsDeleteToolDeleteApiResponse =
  /** status 204 Successful Response */ void;
export type ToolsDeleteToolDeleteApiArg = {
  toolId: number;
};
export type ToolsCallToolPostApiResponse =
  /** status 200 Successful Response */ ToolMessage;
export type ToolsCallToolPostApiArg = {
  toolCall: ToolCall;
};
export type ToolsCallToolByIdPostApiResponse =
  /** status 200 Successful Response */ ToolMessage;
export type ToolsCallToolByIdPostApiArg = {
  toolId: number;
  toolCall: ToolCall;
};
export type ToolsConvertOpenapiToToolCallPostApiResponse =
  /** status 200 Successful Response */ any;
export type ToolsConvertOpenapiToToolCallPostApiArg = {
  bodyToolsConvertOpenapiToToolCallPost: BodyToolsConvertOpenapiToToolCallPost;
};
export type WebWidgetsGetWebWidgetsGetApiResponse =
  /** status 200 Successful Response */ WebWidget[];
export type WebWidgetsGetWebWidgetsGetApiArg = void;
export type WebWidgetsUpdateWidgetPutApiResponse =
  /** status 200 Successful Response */ WebWidget;
export type WebWidgetsUpdateWidgetPutApiArg = {
  webWidgetId: number;
  webWidgetUpdate: WebWidgetUpdate;
};
export type WebWidgetsDownloadLogoGetApiResponse =
  /** status 200 Successful Response */ string;
export type WebWidgetsDownloadLogoGetApiArg = {
  webWidgetId: number;
};
export type RootGetApiResponse = /** status 200 Successful Response */ any;
export type RootGetApiArg = void;
export type Token = {
  access_token: string;
  token_type: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type BodyAdminLoginPost = {
  grant_type?: string | null;
  username: string;
  password: string;
  scope?: string;
  client_id?: string | null;
  client_secret?: string | null;
};
export type Customer = {
  company_name: string;
  id: number;
  email: string;
  api_key: string;
  customer_api_key: string;
  handing_off: boolean;
};
export type CustomerCreateAdmin = {
  company_name: string;
  email: string;
  password: string;
};
export type CustomerHashedId = {
  hashed_id: string;
};
export type CustomerUpdateAdmin = {
  company_name?: string | null;
  email?: string | null;
  api_key?: string | null;
  customer_api_key?: string | null;
  handing_off?: boolean | null;
};
export type Extension = {
  name: string;
  participant_name: string;
  participant_description: string;
  display_name: string;
  description: string;
  id: number;
  owner: number;
  logo_url: string;
  readme_url: string;
  pending_publish: boolean;
  version: string;
  marketplace_url: string;
};
export type MessageLog = {
  message: string;
  ip: string;
  id: number;
  customer_id: number;
  created_at: string;
};
export type MessageRole = "assistant" | "user" | "system" | "tool";
export type FunctionCall = {
  name: string;
  arguments: string;
};
export type ToolCall = {
  id: string;
  type?: "function";
  function: FunctionCall;
};
export type Citation = {
  name: string;
  url?: string | null;
};
export type Reference = {
  id: string;
  name: string;
  value: string;
};
export type MessageExtended = {
  role: MessageRole;
  content: string;
  tool_calls?: ToolCall[] | null;
  citation?: Citation | null;
  reference?: Reference | null;
};
export type PromptResponse = {
  messages: MessageExtended[];
};
export type VsCodeCompletionRequest = {
  query: string;
  history?: string[] | null;
  references?: string[] | null;
  token_limit?: number | null;
  files?: Blob[] | null;
};
export type CompletionResponse = {
  message: string;
  citations: Citation[];
  tool_calls: ToolCall[];
};
export type Message = {
  role: MessageRole;
  content: string;
  tool_calls?: ToolCall[] | null;
};
export type ToolMessage = {
  role?: "tool";
  content: string;
  tool_call_id: string;
};
export type CompletionRequest = {
  query: string;
  history?: (Message | ToolMessage)[] | null;
  references?: Reference[] | null;
  token_limit?: number | null;
};
export type CitationExtended = {
  name: string;
  url?: string | null;
  type: string;
};
export type SearchResponse = {
  sources: CitationExtended[];
};
export type SearchRequest = {
  query: string;
  top_n?: number;
};
export type StreamedCompletionRequest = {
  query: string;
  history?: (Message | ToolMessage)[] | null;
  references?: Reference[] | null;
  token_limit?: number | null;
  sources: CitationExtended[];
};
export type CollectionName = "Main" | "Suggestions" | "Code";
export type Collection = {
  name: CollectionName;
  description: string | null;
  id: number;
  customer_id: number;
};
export type CrawlJobKeys = {
  job_keys: string[];
};
export type CrawlResponse = {
  job_key: string;
};
export type CrawlSiteRequest = {
  url: string;
  url_filters?: string[] | null;
};
export type CrawlSiteStatus = "STARTED" | "CANCELED" | "DONE";
export type CrawlSiteProgress = {
  status?: CrawlSiteStatus | null;
  successful_urls: string[];
  failed_urls: string[];
  visited_urls: string[];
  to_visit_urls: string[];
};
export type CrawlGitHubResponse = {
  file_paths: string[];
};
export type CrawlGitHubRequest = {
  url: string;
  token?: string | null;
};
export type CustomerCreate = {
  company_name: string;
};
export type NameCheck = {
  available: boolean;
};
export type EmbedJobStatus = "QUEUED" | "STARTED" | "CANCELED" | "DONE";
export type EmbedJobsProgress = {
  status?: EmbedJobStatus | null;
  finished: number;
  total: number;
};
export type ExtensionUpdate = {
  name?: string | null;
  participant_name?: string | null;
  participant_description?: string | null;
  display_name?: string | null;
  description?: string | null;
  logo_file?: Blob | null;
  readme_file?: Blob | null;
};
export type ExtensionTemplateOptions = {
  template_names: string[];
};
export type Health = {
  status: string;
};
export type RagConfiguration = {
  customer_id: number;
  system_prompt: string;
};
export type RagConfigurationUpdate = {
  system_prompt: string;
};
export type DocumentSource = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  customer_id: number;
  type: string;
  collection_id: number;
  url?: string | null;
  filename: string;
  extension: string;
};
export type SuggestionSource = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  customer_id: number;
  type: string;
  collection_id: number;
  url?: string | null;
  question: string;
  answer: string;
};
export type WebsiteSource = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  customer_id: number;
  type: string;
  collection_id: number;
  url?: string | null;
};
export type DocumentSourceSupportedTypes = {
  types: string[];
};
export type SourceEmbedding = {
  id: number;
  source_id: number;
  embedding: number[];
  document: string;
  prev_id?: number | null;
};
export type DocumentSourceCreate = {
  name: string;
  file?: Blob | null;
  collection_id?: number | null;
  url?: string | null;
};
export type SuggestionSourceCreate = {
  name: string;
  question: string;
  answer: string;
};
export type EmbedJobKeys = {
  job_keys: string[];
};
export type WebsiteSourceCreate = {
  url: string;
  name?: string | null;
  collection_id?: number | null;
};
export type GitHubSourcesCreate = {
  url: string;
  token?: string | null;
  file_paths: string[];
};
export type SimpleDocumentSourceCreate = {
  name: string;
  url: string;
};
export type DocumentSourceUpdate = {
  name?: string | null;
  file?: Blob | null;
};
export type SuggestionSourceUpdate = {
  name?: string | null;
  question?: string | null;
  answer?: string | null;
};
export type SyncMultiSiteRequest = {
  ids: number[];
};
export type SyncStatus = "QUEUED" | "STARTED" | "DONE";
export type SyncSiteStatus = {
  status?: SyncStatus | null;
};
export type SyncMultiSiteStatus = {
  statuses: {
    [key: string]: SyncStatus | null;
  };
};
export type DeleteSources = {
  ids: number[];
};
export type CrawlSiteMessage = {
  job_key: string;
  customer_id: number;
  url: string;
  url_filters?: string[] | null;
};
export type EmbedSingleSiteMessage = {
  url: string;
  collection_id?: number | null;
  name?: string | null;
};
export type EmbedMultiSiteMessage = {
  job_key: string;
  customer_id: number;
  sites: EmbedSingleSiteMessage[];
};
export type EmbedGitHubMessage = {
  job_key: string;
  customer_id: number;
  token?: string | null;
  owner: string;
  repo: string;
  file_path: string;
};
export type SyncSiteMessage = {
  job_key: string;
  customer_id: number;
  source_id: number;
};
export type MethodType = "GET" | "PATCH" | "POST" | "PUT" | "DELETE";
export type JsonSchemaObject = {
  type?: "object";
  properties: object;
  required?: string[];
  additionalProperties?: boolean;
};
export type ToolArgumentsProperties = {
  path?: JsonSchemaObject | null;
  query?: JsonSchemaObject | null;
  body?: JsonSchemaObject | null;
  auth?: JsonSchemaObject | null;
};
export type ToolArgumentsParameters = {
  type?: "object";
  properties: ToolArgumentsProperties;
  required?: string[];
  additionalProperties?: boolean;
};
export type Tool = {
  name: string;
  url: string;
  description: string;
  method: MethodType;
  parameters?: ToolArgumentsParameters | null;
  strict?: boolean | null;
  id: number;
  customer_id: number;
};
export type ToolArgumentsParameters2 = {
  type?: "object";
  properties: ToolArgumentsProperties;
  required?: string[];
  additionalProperties?: boolean;
};
export type ToolCreate = {
  name: string;
  url: string;
  description: string;
  method: MethodType;
  parameters?: ToolArgumentsParameters2 | null;
  strict?: boolean | null;
};
export type ToolUpdate = {
  name?: string | null;
  url?: string | null;
  description?: string | null;
  method?: MethodType | null;
  parameters?: ToolArgumentsParameters2 | null;
  strict?: boolean | null;
};
export type BodyToolsConvertOpenapiToToolCallPost = {
  /** OpenAPI spec file */
  file: Blob;
};
export type WebWidget = {
  product_name: string;
  primary_color: string;
  id: number;
  owner: number;
  logo_url: string;
};
export type WebWidgetUpdate = {
  product_name?: string | null;
  primary_color?: string | null;
  logo_file?: Blob | null;
};
export const {
  useAdminLoginPostMutation,
  useAdminGetAllCustomersGetQuery,
  useLazyAdminGetAllCustomersGetQuery,
  useAdminCreateCustomerPostMutation,
  useAdminGetCustomerHashedIdGetQuery,
  useLazyAdminGetCustomerHashedIdGetQuery,
  useAdminGetCustomerGetQuery,
  useLazyAdminGetCustomerGetQuery,
  useAdminUpdateCustomerPatchMutation,
  useAdminDeleteCustomerDeleteMutation,
  useAdminGetCustomerFirebaseTokenGetQuery,
  useLazyAdminGetCustomerFirebaseTokenGetQuery,
  useAdminGetAllExtensionsGetQuery,
  useLazyAdminGetAllExtensionsGetQuery,
  useAdminDownloadExtensionObjectGetQuery,
  useLazyAdminDownloadExtensionObjectGetQuery,
  useAdminGetAllMessageLogsGetQuery,
  useLazyAdminGetAllMessageLogsGetQuery,
  useAdminSyncOutdatedSourcesGetQuery,
  useLazyAdminSyncOutdatedSourcesGetQuery,
  useChatVscodeCompletionPostMutation,
  useChatGetCompletionPostMutation,
  useChatSearchPostMutation,
  useChatStreamCompletionPostMutation,
  useChatCopilotEndpointPostMutation,
  useChatGetMessageLogsGetQuery,
  useLazyChatGetMessageLogsGetQuery,
  useCollectionsGetAllCollectionsGetQuery,
  useLazyCollectionsGetAllCollectionsGetQuery,
  useCollectionsGetCollectionGetQuery,
  useLazyCollectionsGetCollectionGetQuery,
  useCrawlsGetCrawlSiteJobIdsGetQuery,
  useLazyCrawlsGetCrawlSiteJobIdsGetQuery,
  useCrawlsCrawlWebsitePostMutation,
  useCrawlsGetCrawlSiteProgressGetQuery,
  useLazyCrawlsGetCrawlSiteProgressGetQuery,
  useCrawlsClearCrawlDeleteMutation,
  useCrawlsCrawlGithubPostMutation,
  useCustomersGetCustomerGetQuery,
  useLazyCustomersGetCustomerGetQuery,
  useCustomersCreateCustomerPostMutation,
  useCustomersGetCustomerTransferringGetQuery,
  useLazyCustomersGetCustomerTransferringGetQuery,
  useCustomersUpdateCustomerEmailPatchMutation,
  useCustomersCheckNameAvailabilityGetQuery,
  useLazyCustomersCheckNameAvailabilityGetQuery,
  useEmbedsGetEmbedJobProgressGetQuery,
  useLazyEmbedsGetEmbedJobProgressGetQuery,
  useExtensionsGetExtensionsGetQuery,
  useLazyExtensionsGetExtensionsGetQuery,
  useExtensionsUpdateExtensionPutMutation,
  useExtensionsDeleteExtensionDeleteMutation,
  useExtensionsPublishExtensionGetQuery,
  useLazyExtensionsPublishExtensionGetQuery,
  useExtensionsDownloadObjectGetQuery,
  useLazyExtensionsDownloadObjectGetQuery,
  useExtensionsListTemplatesGetQuery,
  useLazyExtensionsListTemplatesGetQuery,
  useHealthAllChecksGetQuery,
  useLazyHealthAllChecksGetQuery,
  useHealthDatabaseCheckGetQuery,
  useLazyHealthDatabaseCheckGetQuery,
  useHealthMarketplaceCheckGetQuery,
  useLazyHealthMarketplaceCheckGetQuery,
  useHealthStorageCheckGetQuery,
  useLazyHealthStorageCheckGetQuery,
  useRagConfigGetRagConfigurationGetQuery,
  useLazyRagConfigGetRagConfigurationGetQuery,
  useRagConfigUpdateRagConfigurationPutMutation,
  useSourcesGetSourcesGetQuery,
  useLazySourcesGetSourcesGetQuery,
  useSourcesGetSourceGetQuery,
  useLazySourcesGetSourceGetQuery,
  useSourcesDeleteSourceDeleteMutation,
  useSourcesGetSupportedTypesGetQuery,
  useLazySourcesGetSupportedTypesGetQuery,
  useSourcesGetEmbeddingsGetQuery,
  useLazySourcesGetEmbeddingsGetQuery,
  useSourcesCreateDocumentSourcePostMutation,
  useSourcesCreateSuggestionSourcePostMutation,
  useSourcesCreateWebsiteSourcesPostMutation,
  useSourcesCreateGithubSourcesPostMutation,
  useSourcesCreateSourcesFromLlmsTxtPostMutation,
  useSourcesUpdateDocumentSourcePatchMutation,
  useSourcesUpdateSuggestionSourcePatchMutation,
  useSourcesSyncWebsiteSourceGetQuery,
  useLazySourcesSyncWebsiteSourceGetQuery,
  useSourcesClearSyncWebsiteStatusDeleteMutation,
  useSourcesSyncMultiWebsiteSourcesPostMutation,
  useSourcesGetSyncWebsiteStatusGetQuery,
  useLazySourcesGetSyncWebsiteStatusGetQuery,
  useSourcesGetMultiSyncWebsiteStatusPostMutation,
  useSourcesDeleteSourcesPostMutation,
  useTasksCrawlSitePostMutation,
  useTasksEmbedSitePostMutation,
  useTasksEmbedGithubPostMutation,
  useTasksSyncSitePostMutation,
  useToolsGetToolsGetQuery,
  useLazyToolsGetToolsGetQuery,
  useToolsCreateToolPostMutation,
  useToolsGetToolGetQuery,
  useLazyToolsGetToolGetQuery,
  useToolsUpdateToolPatchMutation,
  useToolsDeleteToolDeleteMutation,
  useToolsCallToolPostMutation,
  useToolsCallToolByIdPostMutation,
  useToolsConvertOpenapiToToolCallPostMutation,
  useWebWidgetsGetWebWidgetsGetQuery,
  useLazyWebWidgetsGetWebWidgetsGetQuery,
  useWebWidgetsUpdateWidgetPutMutation,
  useWebWidgetsDownloadLogoGetQuery,
  useLazyWebWidgetsDownloadLogoGetQuery,
  useRootGetQuery,
  useLazyRootGetQuery,
} = injectedRtkApi;
