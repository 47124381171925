import { Box } from "@mui/material";
import * as React from "react";
import { useAppSelector } from "../../../../state/hooks";
import { selectDarkMode } from "../../../../state/themeSlice";
import CrawlStatus from "./CrawlStatus";
import {
  CrawlSiteProgress,
  useCrawlsClearCrawlDeleteMutation,
  useCrawlsGetCrawlSiteJobIdsGetQuery,
  useLazyCrawlsGetCrawlSiteProgressGetQuery,
} from "../../../../state/layerApi";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import ModalDialog from "../../../../shared/ModalDialog";
import SelectWebsiteSourcesTable from "./SelectWebsiteSourcesTable";

const Crawls = () => {
  const darkMode = useAppSelector(selectDarkMode);
  const { addMessage } = useSnackbarContext();
  const { data, error } = useCrawlsGetCrawlSiteJobIdsGetQuery();
  const [getCrawlProgress] = useLazyCrawlsGetCrawlSiteProgressGetQuery();
  const [clearCrawlJob] = useCrawlsClearCrawlDeleteMutation();

  const [crawlIds, setCrawlIds] = React.useState<string[]>([]);
  const [finishedCrawl, setFinishedCrawl] = React.useState<
    CrawlSiteProgress | undefined
  >(undefined);

  React.useEffect(() => {
    if (error === undefined) return;
    console.error(error);
  }, [error]);

  React.useEffect(() => {
    if (data === undefined) return;
    setCrawlIds((prev) => {
      const crawlIds = [...prev];
      data.job_keys.forEach((id) => {
        if (!crawlIds.includes(id)) crawlIds.push(id);
      });
      return crawlIds;
    });
  }, [data, setCrawlIds]);

  async function onClose(id: string) {
    return clearCrawlJob({ jobKey: id })
      .unwrap()
      .catch((err) => console.error(err))
      .finally(() =>
        setCrawlIds((prev) => {
          const crawlIds = [...prev];
          const index = crawlIds.indexOf(id);
          if (index >= 0) crawlIds.splice(index, 1);
          return crawlIds;
        }),
      );
  }

  async function onError(id: string) {
    return onClose(id).then(() =>
      addMessage("Error crawling website", "error", 60),
    );
  }

  async function onDone(id: string) {
    return getCrawlProgress({ jobKey: id })
      .unwrap()
      .then((response) => setFinishedCrawl(response))
      .catch((err) => console.error(err))
      .finally(() => onClose(id));
  }

  function handleModalClose() {
    setFinishedCrawl(undefined);
  }

  return (
    <>
      <ModalDialog
        label={"Select Website Sources"}
        modalOpen={finishedCrawl !== undefined}
        handleClose={handleModalClose}
        defaultWidth={600}
      >
        <SelectWebsiteSourcesTable
          crawledSites={finishedCrawl?.successful_urls || []}
          handleClose={handleModalClose}
        />
      </ModalDialog>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          display: "flex",
          flexDirection: "column-reverse",
          gap: 1,
          color: darkMode ? "#333" : "#FFF",
        }}
        id={"crawl-statuses"}
      >
        {crawlIds.map((crawlId) => (
          <CrawlStatus
            key={crawlId}
            id={crawlId}
            onError={() => onError(crawlId)}
            onDone={() => onDone(crawlId)}
            onClose={() => onClose(crawlId)}
          />
        ))}
      </Box>
    </>
  );
};

export default Crawls;
