import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {Customer} from "../state/layerApi";
import {Box} from "@mui/material";
import {useAuthContext} from "./AuthContext";
import {store} from "../state/store";
import {setCustomerState} from "../state/customerSlice";
import {signOut} from "firebase/auth";
import {auth} from "../clients/firebase";
import {useSnackbarContext} from "./SnackbarContext";

const CustomerContext = createContext<Customer>({api_key: "", company_name: "", customer_api_key: "", email: "", id: 0, handing_off: false});

export function CustomerContextProvider({children}: { children: ReactNode}) {
    const customerContext = useCustomerContextSetup();
    if (customerContext === null) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                }}
            >
                <p>Loading customer info...</p>
            </Box>
        );
    }
    return (
        <CustomerContext.Provider value={customerContext}>{children}</CustomerContext.Provider>
    );
}

export function useCustomerContext() {
    return useContext(CustomerContext);
}

async function fetchCustomer(token: string) {
    return fetch(`${process.env.REACT_APP_LAYER_URL}customers/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Firebase-Auth": token,
        },
    });
}

function useCustomerContextSetup() {
    const [customer, setCustomer] = useState<Customer | null>(null);
    const user = useAuthContext();
    const {addMessage} = useSnackbarContext();

    useEffect(() => {
        if (user === null) {
            setCustomer(null);
        } else {
            user.getIdToken()
                .then(fetchCustomer)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw Error(`Could not fetch customer data: ${response.status}`);
                    }
                })
                .then((body) => body as Customer)
                .then(setCustomer)
                .catch((err) => {
                    console.error(err);
                    addMessage("Account not found! Please sign up before logging in.", "error", 60);
                    signOut(auth).catch((err) => {
                        console.error(err);
                    });
                });
        }
    }, [user, setCustomer]);

    useEffect(() => {
        if (customer === null) {
            store.dispatch(setCustomerState({id: undefined, api_key: undefined}));
        } else {
            store.dispatch(setCustomerState({id: customer.id, api_key: customer.api_key}));
        }
    }, [customer]);

    return customer;
}