import { Box, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { transparentize } from "polished";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface ToolsTableColumnsProps {
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
}

export function ToolsTableColumns({
  handleEdit,
  handleDelete,
}: ToolsTableColumnsProps): GridColDef[] {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "method",
      headerName: "Method",
      width: 96,
      renderCell: (params) => {
        const colorMap = new Map();
        colorMap.set("GET", "#5691B3");
        colorMap.set("POST", "#679A73");
        colorMap.set("PUT", "#e0973a");
        colorMap.set("PATCH", "#c2b842");
        colorMap.set("DELETE", "#C56767");

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                px: "8px",
                py: "2px",
                bgcolor: transparentize(0.85, colorMap.get(params.value)),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                justifySelf: "flex-start",
                alignSelf: "center",
                height: "fit-content",
              }}
            >
              <Typography
                sx={{
                  height: "fit-content",
                  color: colorMap.get(params.value),
                  fontWeight: 600,
                }}
              >
                {params.value}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "url",
      headerName: "URL",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "authenticated",
      headerName: "Authenticated",
      flex: 1,
      type: "boolean",
    },
    {
      field: "actions",
      width: 48,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          showInMenu
          label="Edit"
          onClick={() => handleEdit(params.id.toString())}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          showInMenu
          label="Delete"
          onClick={() => handleDelete(params.id.toString())}
        />,
      ],
    },
  ];
}
