import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { ToolsTableColumns } from "./ToolsTableColumns";
import ToolsTableToolbar from "./ToolsTableToolbar";
import { useMemo, useState } from "react";
import EditToolModal from "./EditToolModal";
import {
  Tool,
  useToolsDeleteToolDeleteMutation,
  useToolsGetToolsGetQuery,
} from "../../../state/layerApi";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";

const ToolsTable = () => {
  const { addMessage } = useSnackbarContext();

  const { data: tools } = useToolsGetToolsGetQuery();

  const toolRows = useMemo(() => {
    return tools?.map((tool) => ({
      ...tool,
      authenticated: tool.parameters?.properties.auth,
    }));
  }, [tools]);

  const [deleteTool] = useToolsDeleteToolDeleteMutation();

  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>(
    []
  );

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editingTool, setEditingTool] = useState<Tool | undefined>(undefined);

  function handleEditTool(id: string) {
    setEditingTool(tools?.find((tool) => tool.id === parseInt(id)));
    setEditModalOpen(true);
  }

  function handleDeleteTool(id: string) {
    deleteTool({ toolId: parseInt(id) })
      .unwrap()
      .then(() => {
        addMessage("Tool deleted", "success");
      });
  }

  return (
    <>
      <DataGrid
        rows={toolRows || []}
        columns={ToolsTableColumns({
          handleDelete: handleDeleteTool,
          handleEdit: handleEditTool,
        })}
        disableColumnResize
        rowSelectionModel={selectedRowIds}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newSelection) =>
          setSelectedRowIds(newSelection)
        }
        slots={{
          toolbar: () => <ToolsTableToolbar selectedRowIds={selectedRowIds} />,
        }}
      />
      {editingTool && (
        <EditToolModal
          modalOpen={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          tool={editingTool}
        />
      )}
    </>
  );
};

export default ToolsTable;
