import { Group, Row } from "./GroupedGrid/GroupedGrid";
import { Source } from "./types";

export function isValidUrl(urlString: string): boolean {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
}

function handleGuardConditions(
  sites: string[],
  currentDepth: number,
  maxDepth: number
): Record<string, string[]> | null {
  if (currentDepth >= maxDepth || sites.length === 0) {
    return { ungrouped: [] };
  }

  if (sites.length === 1) {
    return { ungrouped: [sites[0]] };
  }

  // No guard condition met, return null
  return null;
}

export function groupSites(
  sites: string[],
  currentDepth: number = 1,
  maxDepth: number = 3,
  groupByDomain: boolean = false
) {
  const guardResult = handleGuardConditions(sites, currentDepth, maxDepth);
  if (guardResult !== null) {
    return guardResult;
  }

  if (groupByDomain && currentDepth === 1) {
    const domainGroups: Record<string, string[]> = {};
    sites.forEach((site) => {
      const siteURL = new URL(site);
      const groupKey = siteURL.protocol + "//" + siteURL.hostname + "/";
      if (!domainGroups[groupKey]) {
        domainGroups[groupKey] = [];
      }
      domainGroups[groupKey].push(site);
    });

    Object.keys(domainGroups).forEach((key) => {
      const nestedGroups = groupSites(
        domainGroups[key],
        currentDepth + 1,
        maxDepth,
        false
      );
      Object.keys(nestedGroups).forEach((nestedKey) => {
        if (nestedKey !== "ungrouped") {
          domainGroups[key + "," + nestedKey] = nestedGroups[nestedKey];
          nestedGroups[nestedKey].forEach((link) => {
            domainGroups[key] = domainGroups[key].filter((l) => l !== link);
          });
        } else {
          nestedGroups[nestedKey].forEach((link) => {
            const siteURL = new URL(link);
            const groupKey = siteURL.protocol + "//" + siteURL.hostname + "/";
            if (!domainGroups[groupKey].includes(link)) {
              domainGroups[groupKey].push(link);
            }
          });
        }
      });
    });

    Object.keys(domainGroups).forEach((key) => {
      if (
        domainGroups[key].length === 1 &&
        !Object.keys(domainGroups).some(
          (k) => k.split(",")[0] === key && k !== key
        )
      ) {
        if (!domainGroups["ungrouped"]) {
          domainGroups["ungrouped"] = [];
        }
        domainGroups["ungrouped"].push(...domainGroups[key]);
        delete domainGroups[key];
      }
    });

    return domainGroups;
  }

  const pathSegmentsList = sites.map((site) => {
    const url = new URL(site);
    if (url.pathname === "/") return [];
    return url.pathname.split("/").filter(Boolean);
  });

  let varyingSegmentIndex = 0;
  while (
    varyingSegmentIndex < pathSegmentsList[0].length &&
    pathSegmentsList.every(
      (segments) =>
        segments[varyingSegmentIndex] ===
        pathSegmentsList[0][varyingSegmentIndex]
    )
  ) {
    varyingSegmentIndex++;
  }

  const groups: Record<string, string[]> = {};
  pathSegmentsList.forEach((segments, index) => {
    const url = new URL(sites[index]);
    const pathname = url.pathname;
    const protocol = url.protocol;
    const hostname = url.hostname;
    const groupUrl = segments[varyingSegmentIndex]
      ? sites[index].slice(
          0,
          protocol.length +
            2 +
            hostname.length +
            pathname.indexOf(segments[varyingSegmentIndex]) +
            segments[varyingSegmentIndex].length
        )
      : "ungrouped";
    const key = groupUrl || "ungrouped";
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(sites[index]);
  });

  Object.keys(groups).forEach((key) => {
    if (groups[key].length === 1) {
      if (!groups["ungrouped"]) {
        groups["ungrouped"] = [];
      }
      groups["ungrouped"].push(...groups[key]);
      delete groups[key];
    }
  });

  Object.keys(groups).forEach((key) => {
    if (key !== "ungrouped") {
      const nestedGroups = groupSites(
        groups[key],
        currentDepth + 1,
        maxDepth,
        false
      );
      Object.keys(nestedGroups).forEach((nestedKey) => {
        if (nestedKey !== "ungrouped") {
          groups[key + "," + nestedKey] = nestedGroups[nestedKey];
          groups[key].forEach((link) => {
            groups[key] = groups[key].filter((l) => l !== link);
          });
        } else {
          nestedGroups[nestedKey].forEach((link) => {
            if (!groups[key].includes(link)) {
              groups[key].push(link);
            }
          });
        }
      });
    }
  });

  sites.forEach((site) => {
    if (!Object.values(groups).some((group) => group.includes(site))) {
      if (groups[site]) {
        groups[site].push(site);
      } else if (site.endsWith("/") && groups[site.slice(0, -1)]) {
        groups[site.slice(0, -1)].push(site);
      } else {
        if (!groups["ungrouped"]) {
          groups["ungrouped"] = [];
        }
        groups["ungrouped"].push(site);
      }
    }
  });

  return groups;
}

export function groupCrawledSites(crawledSites: string[]): {
  rows: Map<string, Row>;
  groups: Map<string, Group>;
} {
  const groupedSites = groupSites(crawledSites);

  const rows = new Map<string, Row>();
  const groups = new Map<string, Group>();

  for (const groupNamesConcat in groupedSites) {
    const urls = groupedSites[groupNamesConcat];
    const groupNames = groupNamesConcat.split(",");

    if (groupNamesConcat === "ungrouped") {
      urls.forEach((url) => {
        rows.set(url, {
          data: { sourceLink: url },
        });
      });
      continue;
    }

    let parentGroup: Group | undefined = undefined;
    for (let i = 0; i < groupNames.length; i++) {
      const parentGroupName = groupNames[i];
      const groupKey = "group_" + parentGroupName;
      
      const matchingUrl = urls.find(url => url === parentGroupName);
      
      if (parentGroup === undefined) {
        parentGroup = groups.get(groupKey);
        if (parentGroup === undefined) {
          parentGroup = {
            data: { sourceLink: parentGroupName },
            groups: new Map<string, Group>(),
            rows: new Map<string, Row>(),
          };
          if (matchingUrl) {
            parentGroup.rows.set(matchingUrl, {
              data: { sourceLink: matchingUrl },
            });
          }
          groups.set(groupKey, parentGroup);
        }
      } else {
        let nextParentGroup: Group | undefined =
          parentGroup.groups.get(groupKey);
        if (nextParentGroup === undefined) {
          nextParentGroup = {
            data: { sourceLink: parentGroupName },
            groups: new Map<string, Group>(),
            rows: new Map<string, Row>(),
          };
          if (matchingUrl) {
            nextParentGroup.rows.set(matchingUrl, {
              data: { sourceLink: matchingUrl },
            });
          }
          parentGroup.groups.set(groupKey, nextParentGroup);
        }
        parentGroup = nextParentGroup;
      }
    }

    if (parentGroup !== undefined) {
      urls.forEach((url) => {
        if (!groupNames.includes(url)) {
          parentGroup!.rows.set(url, {
            data: { sourceLink: url },
          });
        }
      });
    }
  }

  return { rows, groups };
}

export function groupSources(sources: Source[]) {
  const rows = new Map<string, Row>();
  const groups = new Map<string, Group>();

  if (!sources) return { rows, groups };

  const websiteSources = sources?.filter(
    (source) => source.url && source.type === "website"
  );

  const githubSources = sources?.filter((source) => source.type === "github");

  const nonGroupableSources = sources?.filter(
    (source) => source.type !== "website" && source.type !== "github"
  );

  // Add non-groupable sources to rows
  nonGroupableSources.forEach((source) => {
    if ("url" in source && source.url) {
      rows.set(source.id.toString(), {
        data: { ...source, content: source.url },
      });
    } else if ("question" in source && source.question) {
      rows.set(source.id.toString(), {
        data: { ...source, content: source.question },
      });
    } else if ("filename" in source && source.filename) {
      rows.set(source.id.toString(), {
        data: { ...source, content: source.filename },
      });
    } else {
      rows.set(source.id.toString(), {
        data: { ...source, content: "" },
      });
    }
  });

  // Group website sources
  const validUrls = websiteSources
    .map((source) => source.url)
    .filter((url): url is string => url !== null && url !== undefined);

  const groupedSites = groupSites(validUrls, 1, 3, true);

  for (const groupNamesConcat in groupedSites) {
    const urls = groupedSites[groupNamesConcat];
    const groupNames = groupNamesConcat.split(",");

    if (groupNamesConcat === "ungrouped") {
      urls.forEach((url) => {
        const source = websiteSources.find((source) => source.url === url);
        if (source) {
          rows.set(source.id.toString(), {
            data: { ...source, content: source.url },
          });
        }
      });
      continue;
    }

    let parentGroup: Group | undefined = undefined;
    for (let i = 0; i < groupNames.length; i++) {
      const parentGroupName = groupNames[i];
      if (parentGroup === undefined) {
        parentGroup = groups.get(parentGroupName);
        if (parentGroup === undefined) {
          parentGroup = {
            data: {
              name: parentGroupName,
              content: parentGroupName,
              type: "website",
            },
            groups: new Map<string, Group>(),
            rows: new Map<string, Row>(),
          };
          groups.set(parentGroupName, parentGroup);
        }
      } else {
        let nextParentGroup: Group | undefined =
          parentGroup.groups.get(parentGroupName);
        if (nextParentGroup === undefined) {
          nextParentGroup = {
            data: {
              name: parentGroupName,
              content: parentGroupName,
              type: "website",
            },
            groups: new Map<string, Group>(),
            rows: new Map<string, Row>(),
          };
          parentGroup.groups.set(parentGroupName, nextParentGroup);
        }
        parentGroup = nextParentGroup;
      }
    }

    if (parentGroup !== undefined) {
      urls.forEach((url) => {
        const source = websiteSources.find((source) => source.url === url);
        if (source) {
          parentGroup!.rows.set(source.id.toString(), {
            data: { ...source, content: source.url },
          });
        }
      });
    }
  }

  // Group github sources
  const repos = githubSources.reduce((acc, source) => {
    const repo = source.url!.split("/").slice(0, 5).join("/");
    if (!acc.includes(repo)) acc.push(repo);
    return acc;
  }, [] as string[]);

  repos.forEach((repo) => {
    const filesInRepo = githubSources.filter((source) =>
      source.url!.startsWith(repo)
    );
    const groupedFiles = groupFilesInRepo(filesInRepo, repo);
    groups.set(repo, {
      data: {
        name: repo.split("/").slice(3, 5).join("/"),
        content: repo,
        type: "github",
      },
      groups: groupedFiles.groups,
      rows: groupedFiles.rows,
    });
  });

  function groupFilesInRepo(sources: Source[], repoUrl: string) {
    const filePaths = sources.map((source) => source.name);
    const splitFilePaths: string[][] = [];

    filePaths.forEach((filePath, index) => {
      splitFilePaths[index] = [];
      filePath.split("/").forEach((segment) => {
        splitFilePaths[index].push(segment);
      });
    });

    function getRowsAndGroups(splitPaths: string[][], segmentIndex = 0) {
      const rows = new Map<string, Row>();
      const groups = new Map<string, Group>();

      const branchName = sources
        .find(
          (source) =>
            source.type === "github" && source.url?.startsWith(repoUrl)
        )
        ?.url?.split("/")[6];

      splitPaths.forEach((splitPath) => {
        const fullPath = splitPath.join("/");
        const source = sources.find(
          (source) =>
            source.name === fullPath && source.url?.startsWith(repoUrl)
        );
        if (source && splitPath.length === segmentIndex + 1) {
          rows.set(source.id.toString(), { data: { ...source, content: source.url, } });
        } else if (
          source &&
          !groups.has(
            repoUrl + "/" + splitPath.slice(0, segmentIndex + 1).join("/")
          )
        ) {
          const children = splitPaths.filter(
            (sp) =>
              sp.length > segmentIndex + 1 &&
              sp.slice(0, segmentIndex + 1).join("/") ===
                splitPath.slice(0, segmentIndex + 1).join("/")
          );
          const dirUrl = repoUrl + "/" + "tree/" + branchName + "/" + splitPath.slice(0, segmentIndex + 1).join("/");
          groups.set(repoUrl + "/" + splitPath.slice(0, segmentIndex + 1).join("/"), {
            data: {
              name: splitPath[segmentIndex],
              content: dirUrl,
              type: "github",
            },
            groups: getRowsAndGroups(children, segmentIndex + 1).groups,
            rows: getRowsAndGroups(children, segmentIndex + 1).rows,
          });
        }
      });

      return { rows, groups };
    }

    const rowsAndGroups = getRowsAndGroups(splitFilePaths);
    const groups = rowsAndGroups.groups;
    const rows = rowsAndGroups.rows;

    return { groups, rows };
  }

  // Get updated at & created at for each group
  function getGroupDates(groups: Map<string, Group>) {
    groups.forEach((group) => {
      if (group.groups.size > 0) {
        getGroupDates(group.groups);
      }
      if (group.rows.size > 0) {
        const newestUpdatedAt = Array.from(group.rows.values()).reduce(
          (prev, current) => {
            return new Date(prev.data.updated_at ?? "") >
              new Date(current.data.updated_at ?? "")
              ? prev
              : current;
          }
        );
        const oldestCreatedAt = Array.from(group.rows.values()).reduce(
          (prev, current) => {
            return new Date(prev.data.created_at ?? "") <
              new Date(current.data.created_at ?? "")
              ? prev
              : current;
          }
        );

        group.data.updated_at = newestUpdatedAt.data.updated_at;
        group.data.created_at = oldestCreatedAt.data.created_at;
      } else if (group.groups.size > 0) {
        const newestUpdatedAt = Array.from(group.groups.values()).reduce(
          (prev, current) => {
            return new Date(prev.data.updated_at ?? "") >
              new Date(current.data.updated_at ?? "")
              ? prev
              : current;
          }
        );
        const oldestCreatedAt = Array.from(group.groups.values()).reduce(
          (prev, current) => {
            return new Date(prev.data.created_at ?? "") <
              new Date(current.data.created_at ?? "")
              ? prev
              : current;
          }
        );

        group.data.updated_at = newestUpdatedAt.data.updated_at;
        group.data.created_at = oldestCreatedAt.data.created_at;
      }
    });
    return groups;
  }

  getGroupDates(groups);

  return { rows, groups };
}
