import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import JsonSchemaEditor from "./JsonSchemaEditor";
import Ajv, { DefinedError } from "ajv";
import { MethodType, useToolsGetToolsGetQuery } from "../../../state/layerApi";

const propertyDefinition = {
  $id: "propertySchema",
  type: "object",
  required: ["type"],
  properties: {
    type: {
      type: "string",
      enum: ["string", "number", "boolean", "array", "object"],
    },
    properties: {
      type: "object",
      additionalProperties: { $ref: "propertySchema" },
    },
  },
  allOf: [
    {
      if: {
        properties: {
          type: { const: "object" },
        },
        required: ["type"],
      },
      then: {
        required: ["properties"],
      },
      else: {
        not: {
          required: ["properties"],
        },
      },
    },
  ],
  additionalProperties: false,
};

const schemaSchema = {
  type: "object",
  minProperties: 1,
  additionalProperties: propertyDefinition,
};

export interface ToolFormValues {
  name: string;
  description: string;
  url: string;
  method: MethodType | undefined;
  includeAuth?: boolean;
  auth?: string;
  includePath?: boolean;
  path?: string;
  includeQuery?: boolean;
  query?: string;
  includeBody?: boolean;
  body?: string;
}

interface GeneralTabProps {
  values: ToolFormValues;
  setValues: React.Dispatch<React.SetStateAction<ToolFormValues>>;
  errors: Partial<Record<keyof ToolFormValues, string>>;
  validateValues: () => void;
}

const GeneralTab = ({
  values,
  setValues,
  errors,
  validateValues,
}: GeneralTabProps) => {
  return (
    <>
      <TextField
        label="Name"
        variant="filled"
        required
        value={values.name}
        onChange={(e) => {
          if (errors.name) {
            validateValues();
          }
          setValues((prevValues: ToolFormValues) => {
            return { ...prevValues, name: e.target.value };
          });
        }}
        error={errors.name ? true : false}
        helperText={
          errors.name
            ? errors.name
            : "Name must be unique and only contain alphanumeric characters, - or _"
        }
      />
      <TextField
        label="Description"
        variant="filled"
        required
        value={values.description}
        onChange={(e) => {
          if (errors.description) {
            validateValues();
          }
          setValues((prevValues: ToolFormValues) => {
            return { ...prevValues, description: e.target.value };
          });
        }}
        error={errors.description ? true : false}
        helperText={errors.description || ""}
      />
      <TextField
        label="URL"
        variant="filled"
        required
        value={values.url}
        onChange={(e) => {
          if (errors.url) {
            validateValues();
          }
          setValues((prevValues: ToolFormValues) => {
            return { ...prevValues, url: e.target.value };
          });
        }}
        error={errors.url ? true : false}
        helperText={errors.url || ""}
      />
      <FormControl variant="filled" error={errors.method ? true : false}>
        <InputLabel id="method-label">Method *</InputLabel>
        <Select
          labelId="method-label"
          variant="filled"
          required
          value={values.method}
          onChange={(e) => {
            if (errors.method) {
              validateValues();
            }
            setValues((prevValues: ToolFormValues) => {
              return { ...prevValues, method: e.target.value as MethodType };
            });
          }}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="PATCH">PATCH</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem>
        </Select>
        {errors.method && <FormHelperText>{errors.method}</FormHelperText>}
      </FormControl>
    </>
  );
};

type IncludeKeys =
  | "includeAuth"
  | "includePath"
  | "includeQuery"
  | "includeBody";

interface ParameterTabProps extends GeneralTabProps {
  tab: "auth" | "path" | "query" | "body";
}

const ParameterTab = ({
  values,
  setValues,
  tab,
  errors,
  validateValues,
}: ParameterTabProps) => {
  const titleCaseTab = tab.charAt(0).toUpperCase() + tab.slice(1);
  const includeKey = `include${titleCaseTab}` as IncludeKeys;

  function updateValue(value: string) {
    setValues((prevValues: ToolFormValues) => {
      return { ...prevValues, [tab]: value };
    });
  }

  function toggleInclude() {
    setValues((prevValues: ToolFormValues) => {
      return {
        ...prevValues,
        [includeKey]: !prevValues[includeKey],
      };
    });
  }

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => {
          toggleInclude();
        }}
      >
        <Checkbox checked={values[includeKey]} />
        <Typography>Include {titleCaseTab} Parameters?</Typography>
      </Box>
      {values[includeKey] && (
        <JsonSchemaEditor
          schema={values[tab] || ""}
          setSchema={updateValue}
          error={errors[tab]}
        />
      )}
    </>
  );
};

interface ToolFormProps {
  initialValues?: ToolFormValues;
  onSubmit: (values: ToolFormValues) => Promise<void>;
  submitButtonLabel: string;
  toolId?: number;
}

const ToolForm = ({
  initialValues,
  onSubmit,
  submitButtonLabel,
  toolId,
}: ToolFormProps) => {
  // TODO: Handle required properties (including auth, path, query, body, and their children)

  const { data: tools } = useToolsGetToolsGetQuery();

  const [activeTab, setActiveTab] = useState<
    "general" | "auth" | "path" | "query" | "body"
  >("general");

  const [values, setValues] = useState<ToolFormValues>(
    initialValues || {
      name: "",
      description: "",
      url: "",
      method: undefined,
      includeAuth: false,
      auth: "",
      includePath: false,
      path: "",
      includeQuery: false,
      query: "",
      includeBody: false,
      body: "",
    }
  );

  const ajv = new Ajv();
  const validate = ajv.compile(schemaSchema);

  const [errors, setErrors] = useState<
    Partial<Record<keyof ToolFormValues, string>>
  >({});

  function validateValues() {
    const newErrors: Partial<Record<keyof ToolFormValues, string>> = {};
    const requiredFields = ["name", "description", "url", "method"];
    requiredFields.forEach((field) => {
      if (
        values[field as keyof ToolFormValues] === "" ||
        !values[field as keyof ToolFormValues]
      ) {
        newErrors[field as keyof ToolFormValues] =
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      }
    });

    try {
      new URL(values.url);
    } catch {
      newErrors.url = "Invalid URL.";
    }

    if (
      tools?.some((tool) => tool.name === values.name && tool.id !== toolId)
    ) {
      newErrors.name = "Name must be unique.";
    }

    if (!values.name.match(/^[a-zA-Z0-9_-]+$/)) {
      newErrors.name = "Name must only contain alphanumeric characters, - or _";
    }

    const parameterFields = ["auth", "path", "query", "body"];

    parameterFields.forEach((field) => {
      if (
        values[
          `include${field.charAt(0).toUpperCase() + field.slice(1)}` as keyof ToolFormValues
        ]
      ) {
        try {
          if (
            !validate(
              JSON.parse(values[field as keyof ToolFormValues] as string)
            )
          ) {
            const errors = validate.errors as DefinedError[];
            newErrors[field as keyof ToolFormValues] = errors
              ? `${errors[0].instancePath} ${errors[0].message}`
              : "Invalid JSON Schema.";
          }
        } catch (e) {
          newErrors[field as keyof ToolFormValues] = "Invalid JSON.";
        }
      }
    });

    setErrors(newErrors);
    return newErrors;
  }

  function navigateToErrorTab(
    errors: Partial<Record<keyof ToolFormValues, string>>
  ) {
    const errorTabs = Object.keys(errors).reduce<string[]>(
      (errorTabs: string[], errorKey) => {
        if (errorTabs.includes(errorKey)) {
          return errorTabs;
        }
        switch (errorKey) {
          case "auth":
            errorTabs.push("auth");
            break;
          case "path":
            errorTabs.push("path");
            break;
          case "query":
            errorTabs.push("query");
            break;
          case "body":
            errorTabs.push("body");
            break;
          default:
            errorTabs.push("general");
            break;
        }
        return errorTabs;
      },
      []
    );

    if (!errorTabs.includes(activeTab) && errorTabs.length > 0) {
      setActiveTab(
        errorTabs[0] as "auth" | "path" | "query" | "body" | "general"
      );
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="General" value="general" />
        <Tab label="Auth" value="auth" />
        <Tab label="Path" value="path" />
        <Tab label="Query" value="query" />
        <Tab label="Body" value="body" />
      </Tabs>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {activeTab === "general" ? (
          <GeneralTab
            values={values}
            setValues={setValues}
            errors={errors}
            validateValues={validateValues}
          />
        ) : (
          <ParameterTab
            values={values}
            setValues={setValues}
            tab={activeTab}
            errors={errors}
            validateValues={validateValues}
          />
        )}
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          const errors = validateValues();
          if (Object.keys(errors).length > 0) {
            navigateToErrorTab(errors);
            return;
          }
          onSubmit(values);
        }}
      >
        {submitButtonLabel}
      </Button>
    </Box>
  );
};

export default ToolForm;
