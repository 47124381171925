import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import GoogleLogo from "../../../assets/google-logo.svg";
import { createUserWithEmailAndPassword, signInWithPopup, AuthErrorCodes, AuthError } from "firebase/auth";
import { auth, provider } from "../../../clients/firebase";
import { useCustomersCreateCustomerPostMutation } from "../../../state/layerApi";
import CompanyNameForm from "./CompanyNameForm";
import EmailAndPasswordForm from "../EmailAndPasswordForm/EmailAndPasswordForm";
import { LoadingButton } from "@mui/lab";
import { beforeAuthStateChanged, User } from "firebase/auth";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const { addMessage } = useSnackbarContext();
  const [createCustomer] = useCustomersCreateCustomerPostMutation();

  const [companyName, setCompanyName] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const cleanup = beforeAuthStateChanged(auth, (user: User | null) => {
      if (user === null || companyName === undefined) return;
      return user
        .getIdToken()
        .then((token: string) => {
          const url = `${process.env.REACT_APP_LAYER_URL}customers/`;
          const options = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Firebase-Auth": token,
            },
            body: JSON.stringify({ company_name: companyName }),
          };

          return fetch(url, options);
        })
        .then((resp) => {
          if (resp.status !== 200) {
            resp.json().then((data) => console.error(data));
            addMessage("Could not create customer account.", "error", 500);
            throw Error("Could not create customer account.");
          }
        });
    });
    return () => cleanup();
  }, [companyName, createCustomer]);

  function handleSignUp(email: string, password: string) {
    if (companyName === undefined || loading) return;

    setLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .catch((err) => {
        err = err as AuthError;
        if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
          addMessage("Email already in use! Please log in instead.", "error", 60);
        } else {
          addMessage("Could not sign up at this time.", "error", 60);
          console.error(err);
        }
      })
      .finally(() => setLoading(false));
  }

  async function handleGoogleSignUp() {
    if (companyName === undefined || loading) return;

    setLoading(true);
    signInWithPopup(auth, provider)
      .catch((err) => {
        if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
          addMessage("Email already in use! Please log in instead.", "error", 60);
        } else {
          addMessage("Could not sign up at this time.", "error", 60);
          console.error(err);
        }
      })
      .finally(() => setLoading(false));
  }

  if (companyName === undefined) {
    return (
      <>
        <Typography variant="h2">Sign Up</Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CompanyNameForm setCompanyName={setCompanyName} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              flexGrow: 1,
            }}
          >
            <Typography sx={{ textAlign: "center", color: "#757575" }}>Already have an account?</Typography>
            <Button variant="text" onClick={() => navigate("/login")} sx={{ py: 0, px: "2px", minWidth: 0 }}>
              Log in
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="h2">Sign Up</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <>
          <EmailAndPasswordForm submitLabel={"Sign Up"} loading={loading} submit={handleSignUp} />
          <LoadingButton
            variant="contained"
            onClick={handleGoogleSignUp}
            loading={loading}
            sx={{
              elevation: 0,
              bgcolor: "white",
              color: "#333333",
              border: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "row",
              gap: 2,
              ":hover": {
                bgcolor: "#F3F3F3",
              },
            }}
          >
            <img src={GoogleLogo} alt="Google Logo" style={{ width: "24px", height: "24px" }} />
            Sign up with Google
          </LoadingButton>
        </>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Typography sx={{ textAlign: "center", color: "#757575" }}>Already have an account?</Typography>
        <Button variant="text" onClick={() => navigate("/login")} sx={{ py: 0, px: "2px", minWidth: 0 }}>
          Log in
        </Button>
      </Box>
    </>
  );
};

export default SignUp;
