import { useState } from "react";
import { Column, Data, SelectionStatus } from "./GroupedGrid";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

interface HeaderRowProps {
  columns: Column[];
  selectable: boolean;
  selectionStatus?: SelectionStatus;
  selectAllRows: () => void;
  deselectAllRows: () => void;
  sortOptions?: { field: keyof Data; order: "asc" | "desc" };
  setSortOptions?: (sortOptions: {
    field: keyof Data;
    order: "asc" | "desc";
  }) => void;
}

interface HeaderColumnProps {
  column: Column;
  gridColumn: number;
  sortOptions?: { field: keyof Data; order: "asc" | "desc" };
  setSortOptions?: (sortOptions: {
    field: keyof Data;
    order: "asc" | "desc";
  }) => void;
  hovering: keyof Data | undefined;
  setHovering: (field: keyof Data | undefined) => void;
}

const HeaderColumn = ({
  column,
  gridColumn,
  sortOptions,
  setSortOptions,
  hovering,
  setHovering,
}: HeaderColumnProps) => {
  const isSortable = column.sortable && sortOptions && setSortOptions;
  const isSorted =
    sortOptions && setSortOptions && sortOptions.field === column.field;
  const showArrowOnHover = isSortable && hovering === column.field && !isSorted;
  const showArrowUnhovered =
    isSorted && isSortable && (hovering === column.field || !hovering);
  return (
    <Box
      key={column.field}
      sx={{
        gridColumn: gridColumn,
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
      onMouseEnter={() => setHovering(column.field)}
      onMouseLeave={() => setHovering(undefined)}
    >
      <Typography variant="body2" sx={{ fontWeight: "500" }}>
        {column.headerName}
      </Typography>
      {showArrowUnhovered && (
        <IconButton
          size="small"
          sx={{ p: 0.5 }}
          onClick={() => {
            setSortOptions({
              field: column.field,
              order: sortOptions.order === "asc" ? "desc" : "asc",
            });
          }}
        >
          <ArrowUpwardIcon
            sx={{
              transform: `rotate(${sortOptions.order === "asc" ? "0deg" : "180deg"})`,
              fontSize: "16px",
            }}
          />
        </IconButton>
      )}
      {showArrowOnHover && (
        <IconButton
          size="small"
          sx={{ p: 0.5 }}
          onClick={() => {
            setSortOptions({
              field: column.field,
              order: "asc",
            });
          }}
        >
          <ArrowUpwardIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      )}
    </Box>
  );
};

// TODO: Fix columns jumping when sorting
const HeaderRow = ({
  columns,
  selectable,
  selectionStatus,
  selectAllRows,
  deselectAllRows,
  sortOptions,
  setSortOptions,
}: HeaderRowProps) => {
  const [hovering, setHovering] = useState<keyof Data | undefined>(undefined);

  const preExpandColumns = columns.filter(
    (column) => !column.afterExpandColumn
  );
  const postExpandColumns = columns.filter(
    (column) => column.afterExpandColumn
  );

  return (
    <Box
      sx={{
        display: "grid",
        gridColumnStart: 1,
        gridColumnEnd: columns.length + (selectable ? 3 : 2),
        gridTemplateColumns: "subgrid",
        padding: 1,
        borderBottom: "1px solid",
        borderBottomColor: "divider",
        p: 0.5,
        alignItems: "center",
      }}
    >
      {selectable && (
        <Box sx={{ gridColumn: 1 }}>
          <Checkbox
            indeterminate={selectionStatus === "partiallySelected"}
            checked={selectionStatus === "fullySelected"}
            onChange={() => {
              if (selectionStatus === "notSelected") {
                selectAllRows();
              } else {
                deselectAllRows();
              }
            }}
          />
        </Box>
      )}
      {preExpandColumns.map((column) => {
        return (
          <HeaderColumn
            column={column}
            gridColumn={preExpandColumns.indexOf(column) + (selectable ? 2 : 1)}
            hovering={hovering}
            setHovering={setHovering}
          />
        );
      })}
      <Box
        sx={{ gridColumn: preExpandColumns.length + (selectable ? 2 : 1) }}
      />
      {postExpandColumns.map((column) => {
        return (
          <HeaderColumn
            column={column}
            gridColumn={
              preExpandColumns.length +
              postExpandColumns.indexOf(column) +
              (selectable ? 3 : 2)
            }
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
            hovering={hovering}
            setHovering={setHovering}
          />
        );
      })}
    </Box>
  );
};

export default HeaderRow;
