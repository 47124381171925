import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

interface BottomBarProps {
  totalRows: number;
  selectedRowIds: string[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pageCount: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  dataLabel?: string;
  paginationOptions?: {
    pageSizeOptions: number[];
    defaultPageSize: number;
  };
}

// TODO: Add page size selector if pageSizeOptions length > 1
const BottomBar = ({
  totalRows,
  selectedRowIds,
  currentPage,
  setCurrentPage,
  pageCount,
  pageSize,
  setPageSize,
  dataLabel = "row",
  paginationOptions,
}: BottomBarProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: paginationOptions ? "space-between" : "flex-start",
        pl: 2,
        pr: 1,
        py: 1,
        width: "100%",
        borderTop: "1px solid",
        borderTopColor: "divider",
      }}
    >
      <Typography variant="body2">
        {selectedRowIds.length > 0
          ? `${selectedRowIds.length} ${dataLabel}${selectedRowIds.length > 1 ? "s" : ""} selected`
          : `${totalRows} total ${dataLabel}${totalRows > 1 ? "s" : ""}`}
      </Typography>
      {paginationOptions && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          {paginationOptions.pageSizeOptions.length > 1 && (
            <Box sx={{ display: "flex", alignItems: "baseline", gap: 2 }}>
              <Typography variant="body2">Rows per page:</Typography>
              <Select
                value={pageSize}
                variant="standard"
                size="small"
                onChange={(e) => setPageSize(e.target.value as number)}
              >
                {paginationOptions.pageSizeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              size="small"
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            {Array.from({ length: pageCount }).map((_, index) => (
              <Button
                key={index}
                variant={currentPage === index + 1 ? "contained" : "text"}
                size="small"
                sx={{ borderRadius: 2, p: 1, minWidth: "40px" }}
                disableElevation
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Button>
            ))}
            <IconButton
              disabled={currentPage === pageCount}
              onClick={() => setCurrentPage(currentPage + 1)}
              size="small"
            >
              <KeyboardArrowLeftIcon sx={{ transform: "rotate(180deg)" }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BottomBar;
