import * as React from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import Crawls from "./Sources/Crawls/Crawls";
import ScrapeStatus from "./Sources/ScrapeStatus/ScrapeStatus";
import pageProps, { PageProps } from "../../shared/Pages";
import SyncStatus from "./Sources/SyncStatus/SyncStatus";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function getPageFromLocation(location: Location<any>) {
    if (location.pathname === "/") return pageProps[0];
    const page = pageProps.find((page) => location.pathname === page.path);

    if (!page) {
      navigate("/");
      return pageProps[0];
    }

    return page;
  }

  const [page, setPage] = React.useState<PageProps>(
    getPageFromLocation(location)
  );

  React.useEffect(() => {
    setPage(getPageFromLocation(location));
  }, [location, setPage]);

  React.useEffect(() => {
    document.title = page.pageTitle;
  }, [page]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            py: 4,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {page.icon}
            <Typography variant="h2">{page.name}</Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {page.description}
          </Typography>
          {page.children}
        </Box>
      </Container>
      <Crawls />
      <ScrapeStatus />
      <SyncStatus />
    </>
  );
};

export default Dashboard;
