import { Box } from "@mui/material";
import React from "react";
import {
  layerApi,
  useSourcesCreateWebsiteSourcesPostMutation,
} from "../../../../state/layerApi";
import { LoadingButton } from "@mui/lab";
import GroupedGrid from "../../../../shared/GroupedGrid/GroupedGrid";
import { groupCrawledSites } from "../../../../shared/siteUtils";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import { useDispatch } from "react-redux";

interface iSelectedWebsiteSourcesTable {
  crawledSites: string[];
  handleClose: () => void;
}

const SelectWebsiteSourcesTable = ({
  crawledSites,
  handleClose,
}: iSelectedWebsiteSourcesTable) => {
  const dispatch = useDispatch();

  const [postWebsiteSources, { isLoading: isWebsitesLoading }] =
    useSourcesCreateWebsiteSourcesPostMutation();

  const { addMessage } = useSnackbarContext();

  const groupedSites = groupCrawledSites(crawledSites);

  function handleAddSources() {
    if (selectedRowIds.length === 0) return;
    postWebsiteSources({
      sources: selectedRowIds.map((id) => ({ url: id })),
    }).then((response) => {
      if ("data" in response) {
        dispatch(layerApi.util.invalidateTags(["embeds"]));
        handleClose();
      } else if ("error" in response) {
        console.error(response.error);
        addMessage("Failed to start crawl", "error", 60);
      }
    });
  }

  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <GroupedGrid
        columns={[{ field: "sourceLink", headerName: "Source Link" }]}
        rows={groupedSites.rows}
        groups={groupedSites.groups}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        rowsContainerStyleOverrides={{ maxHeight: "400px", overflowY: "auto" }}
      />
      <LoadingButton
        variant="contained"
        disabled={selectedRowIds.length === 0}
        loading={isWebsitesLoading}
        onClick={handleAddSources}
      >
        Add {selectedRowIds.length} Source
        {selectedRowIds.length !== 1 && "s"}
      </LoadingButton>
    </Box>
  );
};

export default SelectWebsiteSourcesTable;
