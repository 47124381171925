import {
  GridRowId,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React from "react";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";
import {
  useLazySourcesSyncWebsiteSourceGetQuery,
  useSourcesDeleteSourcesPostMutation,
  useSourcesSyncMultiWebsiteSourcesPostMutation,
  useSourcesSyncWebsiteSourceGetQuery,
} from "../../../../state/layerApi";
import { Box, Button, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";
import AddSourceModal from "./AddSourceModal";
import { useDispatch } from "react-redux";
import { addSyncingSource } from "../../../../state/syncingSourcesSlice";
import { Source } from "../../../../shared/types";

const SourcesTableToolbarButtons = ({
  selectedRowIds,
  setSelectedRowIds,
  sources,
}: {
  selectedRowIds: GridRowId[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridRowId[]>>;
  sources: Source[];
}) => {
  const dispatch = useDispatch();

  const { addMessage } = useSnackbarContext();
  const [deleteSources, { isLoading: isDeleteLoading }] =
    useSourcesDeleteSourcesPostMutation();

  const [syncSources, { isLoading: isSyncLoading }] =
    useSourcesSyncMultiWebsiteSourcesPostMutation();

  const selectedSources = sources.filter((source) =>
    selectedRowIds.includes(source.id)
  );

  const selectedWebsiteSources = selectedSources.filter(
    (source) => source.type === "website"
  );

  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);

  const handleDeleteSourceRows = () => {
    const sourceIds = selectedRowIds.map((id) => id as number);
    deleteSources({ deleteSources: { ids: sourceIds } })
      .unwrap()
      .then(() =>
        addMessage(
          selectedRowIds.length > 1 ? "Sources deleted" : "Source deleted",
          "success",
          30
        )
      )
      .catch((err) => {
        console.error(err);
        addMessage(
          selectedRowIds.length > 1
            ? "Error deleting sources"
            : "Error deleting source",
          "error",
          60
        );
      });
  };

  const handleSyncSourceRows = (ids: number[]) => {
    syncSources({ syncMultiSiteRequest: { ids: ids } })
      .then(() => {
        dispatch(addSyncingSource(ids));
        setSelectedRowIds([]);
      })
      .catch((err) => {
        console.error(err);
        addMessage("Error syncing sources", "error", 60);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          height: "48px",
        }}
      >
        <Button
          variant="outlined"
          disabled={selectedRowIds.length === 0 || isDeleteLoading}
          color="error"
          onClick={() => setDeleteModalOpen(true)}
          sx={{ minWidth: 0, width: "56px", height: "48px" }}
        >
          <DeleteIcon />
        </Button>
        <Tooltip
          title={`Only website sources ${selectedWebsiteSources.length > 0 ? "will" : "can"} be synced.`}
          arrow
          disableInteractive
        >
          <Box>
            <Button
              variant="outlined"
              disabled={selectedWebsiteSources.length === 0 || isSyncLoading}
              onClick={() =>
                handleSyncSourceRows(
                  selectedWebsiteSources.map((source) => source.id as number)
                )
              }
              sx={{ minWidth: 0, width: "56px", height: "48px" }}
            >
              <SyncIcon />
            </Button>
          </Box>
        </Tooltip>
        <Button
          variant="contained"
          onClick={() => setAddModalOpen(true)}
          sx={{ minWidth: 0, width: "56px", height: "48px" }}
        >
          <AddIcon />
        </Button>
      </Box>
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        itemName={
          selectedRowIds.length > 1
            ? selectedRowIds.length + " Sources"
            : "1 Source"
        }
        setOpen={setDeleteModalOpen}
        handleDelete={handleDeleteSourceRows}
      />
      <AddSourceModal
        modalOpen={addModalOpen}
        handleClose={() => setAddModalOpen(false)}
      />
    </>
  );
};

const SourcesTableToolbar = ({
  selectedRowIds,
  setSelectedRowIds,
  sources,
}: {
  selectedRowIds: GridRowId[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<GridRowId[]>>;
  sources: Source[];
}) => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          p: "12px",
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
        <SourcesTableToolbarButtons
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          sources={sources}
        />
      </Box>
    </GridToolbarContainer>
  );
};

export default SourcesTableToolbar;
