import { Box, Typography } from "@mui/material";
import APISpec from "./APISpec";
import ToolsTable from "./ToolsTable";

const Tools = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">API Spec</Typography>
        <APISpec />
      </Box> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {/* <Typography variant="h6">Endpoints</Typography> */}
        <ToolsTable />
      </Box>
    </Box>
  );
};

export default Tools;
