import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import ModalDialog from "../../../shared/ModalDialog";
import {
  Tool,
  ToolArgumentsProperties,
  ToolUpdate,
  useToolsUpdateToolPatchMutation,
} from "../../../state/layerApi";
import ToolForm, { ToolFormValues } from "./ToolForm";

interface EditToolModalProps {
  modalOpen: boolean;
  handleClose: () => void;
  tool: Tool;
}

const EditToolModal = ({
  modalOpen,
  handleClose,
  tool,
}: EditToolModalProps) => {
  const { addMessage } = useSnackbarContext();

  const [editTool] = useToolsUpdateToolPatchMutation();

  const hasAuth = tool.parameters?.properties.auth ? true : false;
  const hasPath = tool.parameters?.properties.path ? true : false;
  const hasBody = tool.parameters?.properties.body ? true : false;
  const hasQuery = tool.parameters?.properties.query ? true : false;

  async function handleEditTool(values: ToolFormValues) {
    try {
      const paramsMap: {
        key: keyof ToolArgumentsProperties;
        include: boolean | undefined;
        content: string | undefined;
        has: boolean;
      }[] = [
        {
          key: "auth",
          include: values.includeAuth,
          content: values.auth,
          has: hasAuth,
        },
        {
          key: "path",
          include: values.includePath,
          content: values.path,
          has: hasPath,
        },
        {
          key: "body",
          include: values.includeBody,
          content: values.body,
          has: hasBody,
        },
        {
          key: "query",
          include: values.includeQuery,
          content: values.query,
          has: hasQuery,
        },
      ];

      const parameters: ToolArgumentsProperties = {};

      paramsMap.forEach(({ key, include, content, has }) => {
        const oldProps = tool.parameters?.properties[key]?.properties;
        if (
          include &&
          content &&
          JSON.stringify(JSON.parse(content)) !== JSON.stringify(oldProps)
        ) {
          parameters[key] = { properties: JSON.parse(content) };
        } else if (has && !include) {
          parameters[key] = null;
        }
      });

      let removeAllParameters = false;
      const previousParameterCount = paramsMap.filter((p) => p.has).length;

      if (
        Object.values(parameters).every((v) => v === null) &&
        Object.values(parameters).length === previousParameterCount &&
        previousParameterCount > 0
      ) {
        removeAllParameters = true;
      }

      const toolUpdate: ToolUpdate = {};
      if (values.name !== tool.name) toolUpdate.name = values.name;
      if (values.description !== tool.description)
        toolUpdate.description = values.description;
      if (values.method !== tool.method) toolUpdate.method = values.method;
      if (values.url !== tool.url) toolUpdate.url = values.url;
      if (Object.keys(parameters).length)
        toolUpdate.parameters = { properties: parameters };
      if (removeAllParameters) toolUpdate.parameters = null;

      if (Object.keys(toolUpdate).length === 0) {
        handleClose();
        return;
      }

      editTool({
        toolId: tool.id || -1,
        toolUpdate: toolUpdate,
      })
        .unwrap()
        .then(() => {
          addMessage("Tool Updated", "success");
          handleClose();
        })
        .catch((err) => {
          console.error(err);
          addMessage("Failed to update tool", "error");
        });
    } catch (err) {
      console.error(err);
      addMessage("Failed to update tool", "error");
    }
  }

  return (
    <ModalDialog
      label={"Edit Tool"}
      modalOpen={modalOpen}
      handleClose={handleClose}
      hasDefaultWidth
      defaultWidth={600}
    >
      <ToolForm
        onSubmit={(values: ToolFormValues) => handleEditTool(values)}
        submitButtonLabel="Save Changes"
        toolId={tool.id}
        initialValues={{
          name: tool.name || "",
          description: tool.description || "",
          method: tool.method,
          url: tool.url || "",
          includeAuth: hasAuth,
          auth: hasAuth
            ? JSON.stringify(
                tool.parameters?.properties.auth?.properties,
                null,
                4
              )
            : "",
          includePath: hasPath,
          path: hasPath
            ? JSON.stringify(
                tool.parameters?.properties.path?.properties,
                null,
                4
              )
            : "",
          includeBody: hasBody,
          body: hasBody
            ? JSON.stringify(
                tool.parameters?.properties.body?.properties,
                null,
                4
              )
            : "",
          includeQuery: hasQuery,
          query: hasQuery
            ? JSON.stringify(
                tool.parameters?.properties.query?.properties,
                null,
                4
              )
            : "",
        }}
      />
    </ModalDialog>
  );
};

export default EditToolModal;
