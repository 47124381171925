import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface ToolbarProps {
  showSearch: boolean;
  toolbarButtons: React.ReactNode;
  addSearchFilter: (query: string) => void;
  removeSearchFilter: () => void;
}

const Toolbar = ({
  showSearch = true,
  toolbarButtons,
  addSearchFilter,
  removeSearchFilter,
}: ToolbarProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "stretch",
        px: 2,
        py: 1.5,
        width: "100%",
        height: "100%",
        borderBottom: "1px solid",
        borderBottomColor: "divider",
      }}
    >
      {showSearch && (
        <TextField
          variant="filled"
          placeholder="Search..."
          sx={{ width: "100%", minHeight: "100%" }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{height: "100%", mt: "0 !important"}}>
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { height: "100%", "& input": { py: .5 } },
          }}
          onChange={(e) => {
            if (e.target.value) {
              addSearchFilter(e.target.value);
            } else {
              removeSearchFilter();
            }
          }}
        />
      )}
      {toolbarButtons}
    </Box>
  );
};

export default Toolbar;
