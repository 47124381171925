import React, { useMemo, useState } from "react";
import ModalDialog from "../../../../shared/ModalDialog";
import GroupedGrid, {
  Column,
  Group,
  Row,
} from "../../../../shared/GroupedGrid/GroupedGrid";
import FolderIcon from "@mui/icons-material/Folder";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSourcesCreateGithubSourcesPostMutation } from "../../../../state/layerApi";
import { useSnackbarContext } from "../../../../contexts/SnackbarContext";

function groupGithubFiles(filePaths: string[]) {
  const splitFilePaths: string[][] = [];

  filePaths.forEach((filePath, index) => {
    splitFilePaths[index] = [];
    filePath.split("/").forEach((segment) => {
      splitFilePaths[index].push(segment);
    });
  });

  function getRowsAndGroups(splitPaths: string[][], segmentIndex = 0) {
    const rows = new Map<string, Row>();
    const groups = new Map<string, Group>();

    splitPaths.forEach((splitPath) => {
      if (splitPath.length > segmentIndex + 1) return;
      const fullPath = splitPath.join("/");

      const children = splitPaths.filter(
        (sp) =>
          sp.length > splitPath.length &&
          sp.slice(0, splitPath.length).join("/") === fullPath
      );

      if (children.length === 0) {
        rows.set(fullPath, { data: { filePath: fullPath } });
      } else {
        groups.set(fullPath, {
          data: { filePath: fullPath },
          groups: getRowsAndGroups(children, segmentIndex + 1).groups,
          rows: getRowsAndGroups(children, segmentIndex + 1).rows,
        });
      }
    });

    return { rows, groups };
  }

  return getRowsAndGroups(splitFilePaths);
}

interface SelectGithubSourcesModalProps {
  open: boolean;
  handleClose: () => void;
  filePaths: string[];
  repoUrl: string;
}

const SelectGithubSourcesModal = ({
  open,
  handleClose,
  filePaths,
  repoUrl,
}: SelectGithubSourcesModalProps) => {
  const theme = useTheme();

  const columns: Column[] = [
    {
      field: "filePath",
      headerName: "File Path",
      renderCell: (rowOrGroup: Row | Group) => {
        const fileOrDirName = rowOrGroup.data.filePath.split("/").pop();
        if ("groups" in rowOrGroup) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FolderIcon sx={{color: theme.palette.grey[700]}} />
              <Typography>{fileOrDirName}</Typography>
            </Box>
          );
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FileIcon sx={{color: theme.palette.grey[700]}} />
            <Typography>{fileOrDirName}</Typography>
          </Box>
        );
      },
      sortable: true,
    },
  ];

  const [addGithubSources, { isLoading }] =
    useSourcesCreateGithubSourcesPostMutation();
  const { addMessage } = useSnackbarContext();

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const { rows, groups } = groupGithubFiles(filePaths);

  function handleAddSources() {
    addGithubSources({ gitHubSourcesCreate: { url: repoUrl, file_paths: selectedRowIds } })
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.error(err);
        addMessage("Failed to add sources", "error", 60);
      });
  }

  return (
    <ModalDialog
      label="Select Files"
      modalOpen={open}
      handleClose={handleClose}
      defaultWidth={600}
    >
      <GroupedGrid
        columns={columns}
        groups={groups}
        rows={rows}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        defaultSort={{ field: "filePath", order: "asc" }}
        dataLabel="file"
        rowsContainerStyleOverrides={{ maxHeight: "400px", overflowY: "auto" }}
      />
      <LoadingButton
        variant="contained"
        disabled={selectedRowIds.length === 0}
        loading={isLoading}
        onClick={handleAddSources}
      >
        Add {selectedRowIds.length} Source
        {selectedRowIds.length !== 1 && "s"}
      </LoadingButton>
    </ModalDialog>
  );
};

export default SelectGithubSourcesModal;
