import Prism from "prismjs";
import "prismjs/components/prism-json";
import Editor from "react-simple-code-editor";
import "prismjs/themes/prism.css";
import { Box, Typography, useTheme } from "@mui/material";

interface JsonSchemaEditorProps {
  schema: string;
  setSchema: (schema: string) => void;
  error?: string;
}

const JsonSchemaEditor = ({
  schema,
  setSchema,
  error,
}: JsonSchemaEditorProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Editor
        value={schema}
        onValueChange={(code) => setSchema(code)}
        highlight={(code) =>
          Prism.highlight(code, Prism.languages.json, "json")
        }
        padding={10}
        tabSize={4}
        style={{
          borderRadius: "4px",
          border: `1px solid ${error ? "#D3302F" : theme.palette.divider}`,
        }}
      />
      <Typography variant="caption" color="error">
        {error}
      </Typography>
    </Box>
  );
};

export default JsonSchemaEditor;
