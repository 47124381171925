import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface SyncingSourcesState {
    syncingSources: number[];
}

interface SyncingSourcesPayload {
    syncingSources: number[];
}

const initialState: SyncingSourcesState = {syncingSources: []};

const syncingSources = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        setSyncingSources(state, action: PayloadAction<SyncingSourcesPayload>) {
            state.syncingSources = action.payload.syncingSources;
        },
        addSyncingSource(state, action: PayloadAction<number[]>) {
            action.payload.forEach((sourceId) => {
                if (!state.syncingSources.includes(sourceId)) {
                    state.syncingSources.push(sourceId);
                }
            });
        }
    },
});

export const {setSyncingSources, addSyncingSource} = syncingSources.actions;
export default syncingSources.reducer;